/* eslint-disable */

import { CloseOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Modal, Pagination, Result, Select, Spin, Table, Tabs, Tag, notification } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import Slider from "react-slick";
import Footer from "../components/Footer";

import { CopyOutlined } from '@ant-design/icons';
import { Tooltip, message } from 'antd';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TablerCash from '../assets/images/icons/tabler_cash.png';
import axiosCall from '../common/axios_call';
import { _cancel_konnect_request, _fetch_user_wallet_data, _fund_user_wallet, _get_all_transaction_data, _get_bonus_history, _get_konnect_request, _get_user_wallet_account, _get_user_wallet_history } from '../common/axios_services';
import InfoModal from '../components/InfoModal';
import Navbar from '../components/Navbar';
import Profile from '../components/dashboard/side_nav';
import TransferModal from '../components/transactions/TransferModal';


const Loyalty = () => {
    const { TabPane } = Tabs;
    const navigate = useNavigate();
    const { formState: { errors } } = useForm({});
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const params = new URLSearchParams(window?.location?.search);
    const [tx_ref,] = useState(params.get('tx_ref') ? params.get('tx_ref') : '');
    const [status,] = useState(params.get('status') ? params.get('status') : '');
    const [fundKonnect,] = useState(params.get('fundKonnect') ? params.get('fundKonnect') : '');
    const [fundWallet,] = useState(params.get('fundWallet') ? params.get('fundWallet') : '');
    const [minAmount,] = useState(params.get('minAmount') ? params.get('minAmount') : '');
    const [transaction_id,] = useState(params.get('transaction_id') ? params.get('transaction_id') : '');
    const [showTransaction, setShowTransaction] = useState(false)
    const [err_status, setErr_status] = useState(false)
    const [transMessage, setTransMessage] = useState(false)

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const [trans_his, setTrans_his] = useState([])
    const [fetchingLoyalty, setFetchingLoyalty] = useState(true)
    const [accountDetailsModal, setAccountDetailsModal] = useState(((fundKonnect && fundKonnect === "true") || (fundWallet && fundWallet === "true")) ? true : false)
    const [loyalty_his, setLoyalty_his] = useState([])
    const [currency,] = useState("NGN")
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const [total_loyalty, setTotal_loyalty] = useState(0)
    const [total_bonus, setTotal_bonus] = useState(0)
    const [page_size, setPage_size] = useState(10)
    const [page_no, setPage_no] = useState(1)
    const [pageLoy, setPageLoy] = useState(1)
    const [pageSizeLoy, setPageSizeLoy] = useState(10)
    const [bonusPage, setBonusPage] = useState(1)
    const [bonusPageSize, setBonusPageSize] = useState(10)
    const [errorMessage, setErrorMessage] = useState(false)
    const [updateCount, setUpdateCount] = useState(0)
    const [activeHistorySelection, setActiveHistorySelection] = useState({});
    const [activeHistoryDisplayModal, setActiveHistoryDisplayModal] = useState(false);
    const [fundOptionModal, setFundOptionModal] = useState(false);
    const [fundOptions, setFundOptions] = useState((fundKonnect && fundKonnect === "true") ? "konnect" : "wallet");
    const updateCounts = useSelector(state => state.updateCount.count);
    const profileInfo = useSelector(state => state.userProfile.dataFetched);
    const [bonusHistory, setBonusHistory] = useState([]);
    const [bonusFetched, setBonusFetched] = useState(false);
    const [isUpgradeModalVisible, setIsUpgradeModalVisible] = useState(false);
    const [tierOnePayBillModal, setTierOnePayBillModal] = useState(false);



    // UPGRADE MODAL
    const showUpgradeModal = () => {
        setIsUpgradeModalVisible(true);
    };



    const handleCopy = () => {
        message.success('Account number copied to clipboard');
    };

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: '', desc: '' });

    const openModal = (title, desc, lists) => {
        setModalContent({ title, desc, lists });
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };

    const { handleSubmit: handleFundWalletSubmit, control: controlFundWallet, reset: resetFundWallet,
        watch: watchFundWallet, formState: { errors: errorFundWallet } } = useForm({});

    const [walletData, setWalletData] = useState({});
    const [fundModal, setFundModal] = useState(false);
    const [fundUserWalletAmount, setfundUserWalletAmount] = useState(0);
    const [saveCard, setSaveCard] = useState(false);
    const [cancellingKonnect, setCancellingKonnect] = useState(false);
    const [accountDetails, setAccountDetails] = useState(false);
    const [cancelKonnectModal, setCancelKonnectModal] = useState(false);
    const [transToBeCanceled, setTransToBeCanceled] = useState(false);

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title,
            description: message,
            placement: "bottom-right"
        });
    };

    const fetchUserWalletBalance = async e => {
        try {
            let fetchUserAccountBalance = await _fetch_user_wallet_data();
            if (fetchUserAccountBalance.data.status === "success") {
                setWalletData(fetchUserAccountBalance?.data?.data);
            } else {
                setWalletData({});
                openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
            }
        } catch (err) {
            setWalletData({});
            openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
        }
    }

    const getWalletAccount = async () => {
        try {
            let fetchAccounts = await _get_user_wallet_account();
            setAccountDetails(fetchAccounts?.data?.data);
        } catch (err) {
            setAccountDetails({});
            openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
        }
    }


    useEffect(() => {
        fetchUserWalletBalance();
        getWalletAccount()
    }, [updateCounts])

    const fetchUserWalletPayments = async () => {
        try {
            const getPayment = await _get_user_wallet_history({ page: pageLoy, page_size: pageSizeLoy, account_type: "wallet" })
            setLoyalty_his(getPayment.data.data)
            setFetchingLoyalty(false)
            setTotal_loyalty(getPayment?.data?.meta?.total);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', "An error occurred while loading loyalty data. Please reload page to try again")
            }
        }
    }
    const fetchUserTransactionData = async () => {
        try {
            let userWalletTrx = await _get_all_transaction_data({ page, page_size });
            setTrans_his(userWalletTrx.data.data);
            setTotal_trans(userWalletTrx.data.meta.total);
            setFetchingLoyalty(false);
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            setFetchingLoyalty(false);
        }
    }
    const getBounusHistory = async () => {
        setBonusFetched(false);
        try {
            const referrals = await _get_bonus_history({ page: bonusPage, page_size: bonusPageSize });
            if (referrals.data.status === "success") {
                setBonusHistory(referrals?.data?.data);
                setTotal_bonus(referrals?.data?.meta?.total);
                setBonusFetched(true);
            } else {
                openNotificationWithIcon('error', 'An error occurred while loading referral history');
                setBonusHistory([]);
                setBonusFetched(false);
            }
        } catch (err) {
            setBonusFetched(false);
            setBonusHistory([]);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
            openNotificationWithIcon('error', 'An error occurred while loading referral history');
        }
    }
    useEffect(() => {
        getBounusHistory()
    }, [bonusPage, bonusPageSize])
    useEffect(() => {
        if (tx_ref && status && transaction_id) {
            axiosCall(`/transactions/verify_payment_callback?status=${status}&tx_ref=${tx_ref}&transaction_id=${transaction_id}`).then(res => {
                setShowTransaction(true)
                setErr_status(false)
                setTransMessage(res.data.message)
            }).catch(err => {
                setShowTransaction(true)
                if (err.response) {
                    setErr_status(true)
                    setTransMessage(err.response.data.message)
                } else {
                    openNotificationWithIcon('error', "network error")
                }
            })
        }
        fetchUserWalletPayments();
        fetchUserTransactionData();
    }, [per_page, page, tx_ref, status, transaction_id, page_size, page_no, updateCount, updateCounts, pageLoy, pageSizeLoy])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPer_page(pageSize);
    }
    const change_loyalty = (selected_page, pageSize) => {
        setPageLoy(selected_page);
        setPageSizeLoy(pageSize);
        setFetchingLoyalty(true)
    }
    const cancelKonnectRequest = async ({ id }) => {
        setCancellingKonnect(true)
        try {
            let cancelRequest = await _cancel_konnect_request({ id });
            setCancelKonnectModal(false)
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', cancelRequest?.data?.title, cancelRequest?.data?.message)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        } finally {
            setCancellingKonnect(false)
        }
    }

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true
                }
            }
        ]
    };

    const trans_col = [
        {
            title: 'Date',
            dataIndex: 'date',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Reference',
            dataIndex: 'ref',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Service',
            className: 'column-number',
            dataIndex: 'service',
            align: 'center',
        },
        {
            title: 'Total',
            className: 'column-number',
            dataIndex: 'total_amount',
            align: 'center',
        },
        {
            title: 'Amount Paid',
            className: 'column-number',
            dataIndex: 'amount',
            align: 'center',
        },
        {
            title: 'Payment Method',
            className: 'column-number',
            dataIndex: 'paymentmethod',
            align: 'center',
        },
        {
            title: 'Payment Status',
            className: 'column-number',
            dataIndex: 'paymentstatus',
            align: 'center',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        }
    ]

    const cashColumn = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Transaction date',
            dataIndex: 'date',
        },
        {
            title: 'Service',
            dataIndex: 'service',
        },
        {
            title: 'Credit',
            className: 'column-number',
            dataIndex: 'credit',
        },
        {
            title: ' Debit',
            className: 'column-number',
            dataIndex: 'debit',
        },
    ];

    const walletHistoryColumn = [
        {
            title: '',
            dataIndex: 'sn'
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            title: 'Reference',
            dataIndex: 'reference',
        },
        {
            title: 'Service',
            dataIndex: 'service',
        },
        {
            title: 'Status',
            className: 'column-number',
            dataIndex: 'status',
            align: 'center',
        },
        {
            title: 'Credit',
            className: 'column-number',
            dataIndex: 'credit',
            align: 'right',
        },
        {
            title: 'Debit',
            className: 'column-number',
            dataIndex: 'debit',
            align: 'right',
        }
    ]

    let trans_history = trans_his.map((trans, index) => {
        return {
            key: index,
            date: moment(trans?.timestamp).format('HH:mm YYYY-MM-DD'),
            ref: <span style={{ cursor: 'pointer' }} onClick={() => {
                setActiveHistorySelection(trans);
                setActiveHistoryDisplayModal(true);
            }}>{trans.transaction_ref}</span>,
            service: trans.service,
            total_amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans.total_amount) * 1)} />,
            amount: <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(trans.total_amount_paid) * 1)} />,
            paymentmethod: trans.payment_method,
            paymenttype: <div style={{ textTransform: "capitalize" }}>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</div>,
            paymentstatus: <div style={{ textTransform: "capitalize" }}>{trans.payment_status === "full-payment" ? <Tag color="green">Paid</Tag> : trans.payment_status === "no-payment" ? <Tag color="red">Unpaid</Tag> : trans.payment_status === "refund-payment" ? <Tag color="orange">Refund</Tag> : <Tag color="orange">{trans.payment_status}</Tag>}</div>,
            status: <div style={{ textTransform: "capitalize" }}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" || trans.status === "canceled" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div>,
        }
    });
    let bonus_history = bonusHistory.map((trans, index) => {
        return {
            key: index,
            sn: index + 1,
            date: trans?.timestamp?.split('T')[0],
            service: trans.service,
            credit: trans.payment_type === "credit" ? (<div> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </div>) : "-",
            debit: trans.payment_type === "debit" ? (<div> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </div>) : "-",
            balance: <div><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.new_voucher_bal)).toFixed(2)} /></div>,
        }
    });
    let wallet_history_data = loyalty_his.map((trans, index) => {
        return {
            key: index,
            sn: index + 1,
            date: moment(trans?.timestamp).format('HH:mm YYYY-MM-DD'),
            reference: <span style={{ cursor: 'pointer' }} onClick={() => {
                setActiveHistorySelection(trans);
                setActiveHistoryDisplayModal(true);
            }}>{trans.referense_no}</span>,
            service: trans.service,
            credit: <div>{trans.payment_type === "credit" ? <><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.total_amount)).toFixed(2)} /> </> : '-'}</div>,
            debit: <div>{trans.payment_type === "debit" ? <><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.total_amount)).toFixed(2)} /> </> : '-'}</div>,
            balance: <div> <span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.new_wallet_balance)).toFixed(2)} /></div>,
            status: <div style={{ textTransform: "capitalize" }}>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</div>,
        }
    });

    const onChangeValue = e => {
        let amount = 0;
        if (e.target.value.includes('NGN')) {
            amount = e.target.value.split(' ')[1]?.split(',').join('');
        } else {
            amount = e.target.value;
        }
        setfundUserWalletAmount(amount);
    }

    /* finalize fund user wallet */
    const finalizeFundWallet = async e => {
        setSpinnerLoading(true);
        try {
            const profile = await _fund_user_wallet({
                amount: fundUserWalletAmount,
                save_card: saveCard,
                account_type: fundOptions,
            });
            setSpinnerLoading(false);
            window.location = profile.data.data.link;
            setActiveFundWalletData(profile.data.data);
        } catch (err) {
            if (err?.code === "ERR_NETWORK") {
                setErrorMessage('An error occurred while funding wallet. Please check network and try again');
            } else {
                setErrorMessage(err.response.data.message);
            }
            setSpinnerLoading(false);
        }
    }

    const changeBonusPage = (selected_page, pageSize) => {
        setBonusPage(selected_page);
        setBonusPageSize(pageSize);
        setBonusHistory([]);
    }


    return (
        <div className="userprofile">
            <Spin spinning={spinnerLoading} indicator={newAntIcon}>
                <div className="profile-content">
                    <Navbar />
                    <div className="gray_bg_profile">
                        <div className="profile_to_left">
                            <div className="">
                                <div className="profile_nav">
                                    <Profile />
                                </div>
                            </div>
                        </div>
                        <div className="profile_to_right">
                            <div className="">
                                <div className="contain">
                                    <div className="main_container">
                                        <div className="main_container_title_div">
                                            <h5>My Account <button style={{ color: "#1890ff", fontSize: "small", border: "none", background: "#fff" }} onClick={() => setAccountDetailsModal(true)} type='link'>(Account Details)</button></h5>
                                            <Divider style={{ margin: '0px 0 5px' }} />
                                        </div>
                                        {/* Action BTNs  */}
                                        <div className="main_container_inner_div">
                                            <div className="profile_container">
                                                <div className="slider_div_to_top">
                                                    <Slider {...settings}>
                                                        <div key={1} className="item grid-4-box colored color_5 loyalty_card">
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <p>Konnect wallet</p>
                                                                        <h2>
                                                                            <span style={{ marginRight: "3px" }} className="currency">{currency}</span>
                                                                            <NumberFormat thousandSeparator={true} displayType={'text'} value={accountDetails?.available_balance || 0} />
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={2} className="item grid-4-box colored color_1 loyalty_card" onClick={() => openModal('How bonuses work', '', [
                                                            { content: '<b>Get cash bonuses whenever you:</b>' },
                                                            { content: '- Fund your account' },
                                                            { content: '- Download our app' },
                                                            { content: '- Refer other users' },
                                                            { content: '- Recharge your bills' },
                                                            { content: '<b>Use your cash bonus to:</b>' },
                                                            { content: '- Discount shop payments' },
                                                            { content: '- Get extra cash at Wise9ja' },
                                                            { content: '- Get selected monthly for a NGN 50,000 cash gift' },
                                                        ])}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>Bonus balance</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2>
                                                                            <span style={{ marginRight: "3px" }} className="currency">{currency}</span>
                                                                            <NumberFormat thousandSeparator={true} displayType={'text'} value={walletData?.bonus_bal || 0} />
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div key={3} className="item grid-4-box colored color_1 loyalty_card" onClick={() => openModal('Lien Balance', 'Cash kept in lieu for pay after delivery shop transactions.')}>
                                                            <div className="slick-div">
                                                                <div className="slick-box">
                                                                    <div className="icon-box">
                                                                        <img src={TablerCash} alt="tabler cash" />
                                                                    </div>
                                                                    <div className="slider-main-content">
                                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                                                            <p>Lien balance</p>
                                                                            <Tooltip title='Click the box for more info'>
                                                                                <InfoCircleOutlined style={{ color: '#1E90FF', fontSize: '16px' }} />
                                                                            </Tooltip>
                                                                        </div>
                                                                        <h2>
                                                                            <span style={{ marginRight: "3px" }} className="currency">{currency}</span>
                                                                            <NumberFormat thousandSeparator={true} displayType={'text'} value={walletData?.lien_bal || 0} />
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                                <div>
                                                    {((profileInfo?.kyc_status === "unverified") || !profileInfo?.kyc_status) &&
                                                        <div className='space_between'>
                                                            <button onClick={() => navigate('/profile/complete-kyc')} className="bg_side_green">Complete KYC</button>
                                                            <button onClick={() => setFundModal(true)} className="bg_side_green">Fund Account</button>
                                                            <button onClick={() => openModal('More Info', 'Your wallet cash accrued from referral income, sales income and when you fund the wallet.')} className="bg_side_green">More Info</button>
                                                        </div>
                                                    }
                                                    {profileInfo?.tier === "tier_2" ?
                                                        <div className='space_between'>
                                                            <TransferModal />
                                                            <button onClick={() => navigate('/profile/bills')} className="bg_side_green">Bill Payments</button>
                                                        </div> :
                                                        <div className='space_between'>
                                                            <TransferModal/>
                                                            <button onClick={() => setTierOnePayBillModal(true)}
                                                                    className="bg_side_green">Pay Bills
                                                            </button>
                                                            {/*<button onClick={() => navigate('/why-upgrade')}*/}
                                                            {/*        className="bg_side_green">Upgrade Your Account*/}
                                                            {/*</button>*/}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* Tables */}
                                        <div>
                                            <div className="">
                                                <Tabs type='card'>
                                                    <TabPane tab="Wallet history" key="1">
                                                        {fetchingLoyalty ?
                                                            <div>
                                                                <Spin indicator={newAntIcon} />
                                                            </div>
                                                            :
                                                            <>
                                                                <div>
                                                                    <div className="desktop show-all">
                                                                        <Table scroll={{ x: true }} pagination={false} bordered columns={walletHistoryColumn} dataSource={wallet_history_data} />
                                                                        <div style={{ marginTop: "2%" }}></div>
                                                                        <Pagination showSizeChanger current={pageLoy} total={total_loyalty} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={per_page} onChange={change_loyalty} />
                                                                    </div>
                                                                    <div className="mobile topup-data">
                                                                        <Collapse defaultActiveKey={[0]}>
                                                                            {
                                                                                loyalty_his.map((trans, index) => (
                                                                                    <Collapse.Panel showArrow={false} key={index}
                                                                                        header={<p style={{ display: 'inline' }}><span className="small_currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                            className="numeric" displayType={'text'} value={(+trans.total_amount).toFixed(2)} /> {trans.payment_type} on {DateTime.fromISO(trans.timestamp.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}</p>}>
                                                                                        <div>
                                                                                            <ul className="mobile-list-flex">
                                                                                                <li>
                                                                                                    <span>Transaction Ref:</span><span>{trans.referense_no}</span>
                                                                                                </li>
                                                                                                <li><span>Service:</span><span>{trans.service}</span></li>
                                                                                                <li><span>Payment Mode:</span><span>{trans.mode}</span></li>
                                                                                                <li><span>Payment Type:</span><span>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</span></li>
                                                                                                <li><span>Status:</span><span><div style={{ textTransform: "capitalize" }}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div></span></li>
                                                                                                <li><span>Action:</span><span><Button onClick={() => { setActiveHistorySelection(trans); setActiveHistoryDisplayModal(true); }}>View</Button></span></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </Collapse.Panel>
                                                                                ))}
                                                                        </Collapse>
                                                                        <div style={{ marginTop: "2%" }}></div>
                                                                        <Pagination showSizeChanger current={pageLoy} total={total_loyalty} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={per_page} onChange={change_loyalty} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </TabPane>
                                                    <Tabs.TabPane tab="Bonus History" key="2">
                                                        <div className="px-4 table-responsive">
                                                            {bonusFetched ?
                                                                <>
                                                                    <div className="desktop show-all">
                                                                        <Table columns={cashColumn} pagination={false} dataSource={bonus_history} bordered />
                                                                    </div>

                                                                    <div className="mobile topup-data">
                                                                        <Collapse defaultActiveKey={[0]}>
                                                                            {
                                                                                bonusHistory.map((trans, index) => (
                                                                                    <Collapse.Panel showArrow={false} key={index}
                                                                                        header={<p style={{ display: 'inline' }}>{trans.payment_type === "credit" ? (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>)
                                                                                            : (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>)}{trans.service} on {DateTime.fromISO(trans?.timestamp?.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}</p>}>
                                                                                        <div>
                                                                                            <ul className="mobile-list-flex">
                                                                                                <li>
                                                                                                    <span>Service:</span><span>{trans.service}</span>
                                                                                                </li>
                                                                                                <li><span>Date:</span><span>{trans?.timestamp?.split('T')[0]}</span></li>
                                                                                                <li><span>Credit:</span>{trans.payment_type === "credit" ? (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>) : <span>&mdash;</span>}</li>
                                                                                                <li><span>Debit:</span>{trans.payment_type === "debit" ? (<span> <span className="currency">NGN</span> <NumberFormat thousandSeparator={true} displayType={'text'} value={Math.trunc(+trans.amount).toFixed(2)} /> </span>) : <span>&mdash;</span>}</li>
                                                                                                <li><span>Balance:</span><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+trans.new_voucher_bal)).toFixed(2)} /></span></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </Collapse.Panel>
                                                                                ))}
                                                                        </Collapse>
                                                                        <div style={{ marginTop: "2%" }}></div>
                                                                    </div>
                                                                    <Pagination showSizeChanger current={bonusPage} total={total_bonus} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={bonusPageSize} onChange={changeBonusPage} />
                                                                </>
                                                                :
                                                                <div className="">
                                                                    <Spin />
                                                                </div>
                                                            }
                                                        </div>
                                                    </Tabs.TabPane>
                                                    <TabPane tab="Transaction History" key="3">
                                                        {!trans_his ?
                                                            <div>
                                                                <Spin indicator={newAntIcon} />
                                                            </div> :
                                                            <>
                                                                <div className="desktop show-all">
                                                                    <Table pagination={false} bordered columns={trans_col} dataSource={trans_history} />
                                                                    <div style={{ marginTop: "2%" }}></div>
                                                                    <Pagination showSizeChanger current={page} total={total_trans} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={per_page} onChange={change_page} />
                                                                </div>
                                                                <div className="mobile topup-data">
                                                                    <Collapse defaultActiveKey={[0]}>
                                                                        {
                                                                            trans_his.map((trans, index) => (
                                                                                <Collapse.Panel showArrow={false} key={index}
                                                                                    header={<p style={{ display: 'inline' }}><span className="small_currency">NGN</span><NumberFormat thousandSeparator={true} prefix={''}
                                                                                        className="numeric" displayType={'text'} value={(+trans.amount).toFixed(2)} /> {trans.payment_type} on {DateTime.fromISO(trans.created_at.split('T')[0]).toLocaleString(DateTime.DATE_HUGE)}</p>}>
                                                                                    <div>
                                                                                        <ul className="mobile-list-flex">
                                                                                            <li>
                                                                                                <span>Transaction Ref:</span><span>{trans.transaction_ref}</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <span>Service:</span><span>{trans.service}</span>
                                                                                            </li>
                                                                                            <li><span>Amount:</span><span><span>
                                                                                                <span className="small_currency">NGN</span>
                                                                                                <NumberFormat thousandSeparator={true} displayType={'text'} value={(Number(trans.total_amount_paid).toFixed(2))} /></span></span></li>
                                                                                            <li><span>Payment Method:</span><span>{trans.payment_method}</span></li>
                                                                                            <li><span>Payment Status:</span><span>
                                                                                                <div style={{ textTransform: "capitalize" }}>{trans.payment_type === "credit" ? <Tag color="green">{trans.payment_type}</Tag> : <Tag color="red">{trans.payment_type}</Tag>}</div></span></li>
                                                                                            <li><span>Status:</span><span><div style={{ textTransform: "capitalize" }}>{trans.status === "success" ? <Tag color="#87d068">{trans.status}</Tag> : trans.status === "cancel" ? <Tag color="#cf1322">{trans.status}</Tag> : <Tag color="#ffa500">{trans.status}</Tag>}</div></span></li>
                                                                                            <li><span>Date:</span><span>{moment(trans?.timestamp).format('HH:mm YYYY-MM-DD')}</span></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Collapse.Panel>
                                                                            ))}
                                                                    </Collapse>
                                                                    <div style={{ marginTop: "2%" }}></div>
                                                                    <Pagination showSizeChanger current={pageLoy} total={total_loyalty} showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} transaction`} defaultPageSize={per_page} onChange={change_loyalty} />
                                                                </div>
                                                            </>
                                                        }
                                                    </TabPane>
                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
            {/* Modals */}
            <Modal centered={true} title="" open={fundOptionModal} onCancel={() => setFundOptionModal(false)} footer={null}>
                <div>
                    <h4 className="modal-title">Fund Account</h4>
                    <div className="form-group">
                        <p className="modal-des">Select an account you want to fund.</p>
                        <Select placeholder="Select Options" defaultValue={fundOptions} onChange={({ value }) => setFundOptions(value)} labelInValue optionFilterProp="value" >
                            <Select.Option value="wallet" >Fund wallet balance</Select.Option>
                            <Select.Option value="konnect" >Fund Konnect balance</Select.Option>
                        </Select>
                    </div>
                    <div style={{ marginTop: '5%' }}></div>
                    <button onClick={() => { setFundModal(true); setFundOptionModal(false) }} style={{ display: 'block', width: "100%", height: '5rem', borderRadius: '2px', margin: "10px 0px" }} className="bg_green stretch">Fund Account</button>
                    <button style={{ width: '100%', border: 'none', textAlign: 'center', background: 'transparent', outline: 'none', paddingTop: 10 }} onClick={e => { e.preventDefault(); setFundOptionModal(false) }}>Cancel</button>
                </div>
            </Modal>
            <Modal centered={true} title="" open={fundModal} onCancel={() => setFundModal(false)} footer={null}>
                <div>
                    <h4 className="modal-title">Fund Account</h4>
                    {minAmount &&
                        <p>To complete your order, credit your account with a minimum of <b>NGN <NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+minAmount)).toFixed(2)} /></b>.</p>
                    }
                    {!minAmount &&
                        <>
                            {fundOptions === "wallet" &&
                                <p>You have a wallet balance of <b><NumberFormat thousandSeparator={true} value={walletData?.wallet_bal || 0} prefix={'NGN '} displayType="text" className="numeric" /></b>.</p>
                            }
                            {fundOptions === "konnect" &&
                                <p>You have a konnect balance of <b><NumberFormat thousandSeparator={true} value={walletData?.konnect_bal || 0} prefix={'NGN '} displayType="text" className="numeric" /></b>.</p>
                            }
                        </>
                    }
                    {errorMessage ?
                        <p className="form-error">{errorMessage}</p> : ''
                    }
                    <form onSubmit={handleFundWalletSubmit(finalizeFundWallet)}>
                        <div className="">
                            <div className="form-group">
                                <label htmlFor="amount">Amount</label>
                                <Controller name="amount" control={controlFundWallet}
                                    render={
                                        ({ field }) => (
                                            <NumberFormat thousandSeparator={true}
                                                prefix={'NGN '}
                                                className="numeric"
                                                onInput={e => onChangeValue(e)}
                                                {...field} style={{ height: '5rem', width: '100%' }} />
                                        )
                                    } />
                                {errors.amount && <p className="form-error">{errors.amount.message}</p>}
                            </div>
                            <div style={{ marginTop: '10%' }}></div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                                    {!spinnerLoading ?
                                        <button type="submit" style={{ display: 'block', height: '4rem', padding: '0px 25px 0px', borderRadius: '2px', margin: "10px 0px" }} className="bg_green stretch" >Fund Account</button>
                                        :
                                        <button disabled loading style={{ display: 'block', height: '4rem', padding: '0px 25px 0px', borderRadius: '2px', margin: "10px 0px", opacity: "0.5" }} className="bg_green stretch" ><Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                    }
                                    {/* {(!minAmount && !fundKonnect && !fundWallet) &&
                                            <button style={{ width: 'fit-content', height: '4rem', padding: '0px 25px 0px', border: '1px solid gray', textAlign: 'center', background: 'transparent', outline: 'none', paddingTop: 10 }} onClick={e => { e.preventDefault(); setFundModal(false); setFundOptionModal(true) }}>Back</button>
                                        } */}
                                </div>
                                <button style={{ width: 'fit-content', padding: '0px 25px 0px', height: "4rem", border: '1px solid gray', textAlign: 'center', background: 'transparent', outline: 'none', paddingTop: 10 }} onClick={e => { e.preventDefault(); setFundModal(false); navigate('/profile/loyalty') }}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal >
            <Modal centered={true} title="" open={activeHistoryDisplayModal} onCancel={() => setActiveHistoryDisplayModal(false)} footer={null}>
                <div className="extra-info-modal">
                    <ul className="mobile-list-flex">
                        <li>
                            <span>Transaction Ref:</span><span className='adjust'>{activeHistorySelection.referense_no ? activeHistorySelection.referense_no : activeHistorySelection.transaction_ref}</span>
                        </li>
                        <li><span>Service:</span><span className='adjust'>{activeHistorySelection.service}</span></li>
                        <li><span>Amount:</span><span className='adjust'><span className='currency'>NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={+activeHistorySelection?.total_amount?.toFixed(2)} /></span></li>
                        <li><span>Payment Mode:</span><span className='adjust'>{activeHistorySelection.mode ? activeHistorySelection.mode : activeHistorySelection.payment_method}</span></li>
                        <li><span>Payment Status:</span><span className='adjust'>{activeHistorySelection.payment_type === "credit" ? <Tag color="green">{activeHistorySelection.payment_type}</Tag> : <Tag color="red">{activeHistorySelection.payment_type}</Tag>}</span></li>
                        <li><span>Status:</span><span className='adjust'><div style={{ textTransform: "capitalize" }}>{activeHistorySelection.status === "success" ? <Tag color="#87d068">{activeHistorySelection.status}</Tag> : activeHistorySelection.status === "cancel" ? <Tag color="#cf1322">{activeHistorySelection.status}</Tag> : <Tag color="#ffa500">{activeHistorySelection.status}</Tag>}</div></span></li>
                        <li><span>Date:</span><span className='adjust'>{moment(activeHistorySelection?.timestamp).format('HH:mm YYYY-MM-DD')}</span></li>
                        <li><span>Description:</span><span className='adjust'>{activeHistorySelection.description}</span></li>
                    </ul>

                    <button style={{
                        display: 'block', width: "100%", height: '5rem',
                        borderRadius: '2px', margin: "10px 0px"
                    }} className="bg_green stretch" onClick={e => {
                        e.preventDefault();
                        setActiveHistoryDisplayModal(false)
                    }}>Close</button>
                </div>
            </Modal>
            <Modal centered={true} title="" open={cancelKonnectModal} onCancel={() => setCancelKonnectModal(false)} footer={null}>
                <div className="extra-info-modal">
                    <h2>Cancel Konnect Transaction</h2>
                    <p>Are you sure you want to cancel the transaction <b><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={(Math.trunc(+transToBeCanceled?.total_amount)).toFixed(2)} /></b></p>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Button onClick={() => setCancelKonnectModal(false)}>Close</Button>
                        {cancellingKonnect ?
                            <Button type='primary' disabled loading>Proceed</Button> :
                            <Button type='primary' onClick={() => cancelKonnectRequest({ id: transToBeCanceled?.id })}>Proceed</Button>
                        }
                    </div>
                </div>
            </Modal>
            <Modal centered={true} title="" open={accountDetailsModal} onCancel={() => setAccountDetailsModal(false)} footer={null}>
                <div className="extra-info-modal">
                    <h2>Account Details</h2>
                    {accountDetails ?
                        <>
                            {accountDetails?.status === "ACTIVE" || accountDetails?.status === "active" ?
                                <>
                                    {minAmount &&
                                        <p>To complete your order, credit your account with a minimum
                                            of <b>NGN <NumberFormat thousandSeparator={true} displayType={'text'}
                                                                    value={(Math.trunc(+minAmount)).toFixed(2)}/></b>.
                                        </p>
                                    }
                                    <div className='justify_between'>
                                        <p>Account Name:</p>
                                        <p>{accountDetails?.account_name}</p>
                                    </div>
                                    <div className='justify_between' style={{marginTop: "10px"}}>
                                        <p>Tier Type:</p>
                                        <p style={{textTransform: "capitalize"}}>{profileInfo?.tier}</p>
                                    </div>
                                    <div className='justify_between' style={{marginTop: "10px"}}>
                                        <p>Account Number:</p>
                                        <div style={{display: "flex"}}>
                                            <p>{accountDetails?.account_number}</p>
                                            <span style={{
                                                padding: "3px",
                                                background: "#b9feb9",
                                                borderRadius: "5px",
                                                marginLeft: "5px"
                                            }}>
                                                <CopyToClipboard text={accountDetails?.account_number}
                                                                 onCopy={handleCopy}>
                                                    <Tooltip title="Copy">
                                                        <CopyOutlined style={{cursor: 'pointer'}}/>
                                                    </Tooltip>
                                                </CopyToClipboard>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='justify_between' style={{marginTop: "10px"}}>
                                        <p>Bank Name:</p>
                                        <p>{accountDetails?.bank_name}</p>
                                    </div>
                                    <p style={{textAlign: 'center', fontSize: '1.3rem', color: 'red'}}>We pay back
                                        charges incurred when you fund your wallet to your bonus balance.</p>
                                </> :
                                <>
                                    <p>We're creating your account details. Please refresh the page after 2 minutes.</p>
                                </>
                            }
                        </>
                        :
                        <>
                            <Spin indicator={antIcon}/>
                        </>
                    }
                    <div className={'flex justify-between'}>
                        <Button onClick={() => setAccountDetailsModal(false)} style={{marginTop: "10px"}}>Close</Button>
                        {
                            // accountDetails?.available_balance >= 1000 && profileInfo?.tier === "tier_1"
                            (profileInfo?.tier === "tier_1")  && (
                                <Button onClick={showUpgradeModal} className={'mt-10 bg-[#008060] text-white rounded-3xl  '} style={{marginTop: "10px", backgroundColor: "#008060"}}>Upgrade Your Account</Button>
                            )
                        }
                    </div>
                </div>
            </Modal>

            <InfoModal
                title={modalContent.title}
                desc={modalContent.desc}
                visible={modalVisible}
                onClose={closeModal}
                lists={modalContent.lists || []}
            />
            {showTransaction &&
                <div style={{ width: "100%", height: "100vh", position: "absolute", top: "0", left: "0", background: "rgba(0,0,0,0.2)", display: "flex" }}>
                    <div style={{ width: "fit-content", background: 'white', margin: "auto", zIndex: "100" }}>
                        {err_status ?
                            <Result
                                status="success"
                                title={transMessage}
                                subTitle=""
                                extra={[
                                    <Button key="buy" onClick={() => setShowTransaction(false)}>Ok</Button>,
                                ]}
                            />
                            :
                            <Result
                                status="success"
                                title={transMessage}
                                subTitle=""
                                extra={[
                                    <Button key="buy" onClick={() => setShowTransaction(false)}>Ok</Button>,
                                ]}
                            />
                        }
                    </div>
                </div>
            }
            <div className="mobile">
                <Footer />
            </div>
            <Modal
                open={isUpgradeModalVisible}
                centered={true}
                footer={null}
                title="Upgrade Notification"
            >
                <p>We will advise you in a few days when you can upgrade to Tier 2.</p>
                <div className={'flex justify-end'}>
                    <button
                        onClick={ () => {
                            setIsUpgradeModalVisible(false);
                            // setAccountDetailsModal(false);
                       }}
                      className={'py-3 px-10 bg-green-800 text-white rounded-full'}>
                        Got it
                    </button>
                </div>
            </Modal>

            {/*TIER ONE PAY BILL MODAL*/}

            <Modal
                open={tierOnePayBillModal}
                onCancel={() => setTierOnePayBillModal(false)}
                centered={true}
                footer={null}
                title="Pay Bill Notification"
            >
                <p>Bill payments (airtime and data recharge) for Tier 1 users will resume soon.</p>
            </Modal>
        </div>
    )
}
export default Loyalty;