import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const { TabPane } = Tabs;



const MobileDashTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('nav1');

    useEffect(() => {
        // Update the active key based on the current path
        switch (location.pathname) {
            case '/restaurant/dashboard':
                setActiveKey('nav1');
                break;
            case '/restaurant/menu':
                setActiveKey('nav2');
                break;
            case '/restaurant/profile':
                setActiveKey('nav4');
                break;
            case '/contact':
                setActiveKey('nav5');
                break;
            default:
                setActiveKey('nav1'); // Default to Dashboard
                break;
        }
    }, [location.pathname]);

    const handleTabChange = (key) => {
        setActiveKey(key);
        switch (key) {
            case 'nav1':
                navigate('/restaurant/dashboard');
                break;
            case 'nav2':
                navigate('/restaurant/menu');
                break;
            case 'nav4':
                navigate('/restaurant/profile');
                break;
            case 'nav5':
                navigate('/contact');
                break;
            default:
                break;
        }
    };

    return (
        <div className="mobile-nav-tabs hidden max-[720px]:block  ">
            <Tabs
                activeKey={activeKey}
                onChange={handleTabChange}
                tabPosition="top"
                style={{ overflowX: 'auto' }}
            >
                <TabPane 
                    tab={
                        <>
                            <ion-icon class="profile_icon" name="wallet-outline"></ion-icon> Dashboard
                        </>
                    }
                    key="nav1"
                />
                <TabPane
                    tab={
                        <>
                            <ion-icon class="profile_icon" name="people-outline"></ion-icon> My Menu
                        </>
                    } 
                    key="nav2" 
                />
                <TabPane
                    tab={
                        <>
                            <ion-icon class="profile_icon" name="card-outline"></ion-icon> My Profile
                        </>
                    }
                    key="nav4"
                />
                <TabPane
                    tab={
                        <>
                            <ion-icon class="profile_icon" name="person-outline"></ion-icon> Support
                        </>
                    }
                    key="nav5"
                />
            </Tabs>
        </div>
    );
};

export default MobileDashTabs;
