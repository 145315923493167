import '../assets/css/landing.css';

import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

import { Link } from 'react-router-dom';
import Apple from '../assets/images/apple_store.png';
import SideImage from '../assets/images/landing/1.png';
import play from '../assets/images/play_store.png';
import HowItWorks from '../components/HowItWorks';

const LandingPage = () => {
    const how_bill_works = {
        title: "How it works",
        description: "",
        lists: [
            { content: 'Get up to 7% cash bonus when you recharge your airtime, data, TV & electricity.' },
            { content: 'Your cash bonus selects you to get up to NGN 50,000 monthly.' },
            { content: 'The more you recharge, the better your chances.' },
        ]
    }
    return (
        <>
            <Navbar disapear={true} />
            <div className='landing_hero' style={{ width: "100%", backgroundColor: "#f1fef4", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <div style={{ padding: "0 2%" }} className='grid_flex'>
                    <div>
                        <h3 style={{ color: "rgba(0,0,0,.85)", fontSize: "4.5rem", fontFamily: "Gilroy Bold", marginBottom: "2rem" }}>Download our mobile app & we credit your wallet with a cash gift.</h3>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '10px' }}>
                            <a target="_blank" href="https://apps.apple.com/app/konnect-ws/id6504823567">
                                <img style={{ height: "5rem", width: "auto" }} src={Apple} className="download" alt="download on apple store" />
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wnapp.id1695026422582">
                                <img style={{ height: "5rem", width: "auto" }} src={play} className="download" alt="download on google play store" />
                            </a>
                        </div>
                    </div>
                    <div style={{ display: "block" }}>
                        <img src={SideImage} alt="" style={{ margin: "0 auto", display: "block" }} />
                    </div>
                </div>
            </div>
            {/* <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Recharge your bills & earn extra cash every 30 days <span style={{ fontWeight: 'normal' }}><HowItWorks style={{ fontWeight: "normal" }} data={how_bill_works} /></span></h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'><span className='card_info_no' style={{ fontSize: "6rem" }}>3</span> Reasons why Food Vendors Loves Konnect!!!</h4>
                        <Link className='joinus_button' style={{ margin: "auto" }} to="/vendor">Click Here</Link>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Become a Support Manager & earn weekly, <Link to="/joinus">Click To Start</Link></h4>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", overflow: "hidden" }} >
                <div className='grid_card card_padding_join'>
                    <div className='card_info_join'>
                        <h4 className='card_info_title'>Welcome to Konnect.ws, the Wealthy Place</h4>
                    </div>
                </div>
            </div> */}
            <Footer noSpace={true} />
        </>
    )
}

export default LandingPage;
