import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import restaurant_food from '../assets/images/homepage/restaurant_food.png';
import { useNavigate } from 'react-router-dom';
import oops from "../assets/images/restaurant/oops.jpg";

export const FoodImageArea = ({restaurants, }) => {
    const navigate = useNavigate()
  return (
        // <div className="lg:p-8  ">
            <div className='bg-white px-2 md:px-5 py-5 rounded-3xl '>
                <div className="align-center my-5">
                    <div className={'px-4'}>
                        <h1 className='text-5xl' >Just for you! </h1>
                        <p style={{ fontSize: '1.5rem', fontWeight: "medium", color: "#959595" }}>Based on your frequent orders</p>
                    </div>
                    {/*<Button type="link">View all</Button>*/}
                </div>
                <div className="hidden mt-8 md:flex">
                    <Row gutter={[32, 32]} className={'w-full items-stretch  '}>
                        {!restaurants || restaurants.length === 0 ?
                            (
                                <div className={"flex flex-col justify-center items-center"}>
                                    <img src={oops} width={200} className={"mx-auto"} alt='oops' />
                                    <h3>No Restaurant Found in your selected city</h3>
                                </div>
                            ) :
                            restaurants.map((restaurant) => {
                                return (
                                    <Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>
                                        <Card
                                            key={restaurant.id}
                                            className="restaurant-card foodImgArea cursor-pointer"
                                            onClick={() => navigate(`/restaurant/${restaurant.id}`)}
                                        >
                                            <img
                                                className='object-cover transition-transform duration-300 ease-in-out transform hover:scale-105' alt="Restaurant img "
                                                src={restaurant.display_image}
                                                alt={restaurant.name}
                                            />

                                            <div className="align-center">
                                                <h3 className="font-bold ">{restaurant.name}</h3>
                                                <span className="text-red-500 text-lg">❤️</span>
                                            </div>
                                            <div className="align-center">
                                                <p style={{fontSize: "12px"}}>{` ${restaurant.min_ready_time} mins`}</p>
                                                <span className="text-yellow-500">⭐ {restaurant.rating || 'N/A'}</span>
                                            </div>
                                        </Card>
                                    </Col>
                                );
                            })}
                        {/*<Col xs={24} sm={12} md={8} style={{ display: 'flex' }}>*/}
                        {/*    <Card className='restaurant-card cursor-pointer'  onClick={() => navigate('/restaurant/1')} >*/}
                        {/*        <img src={restaurant_food} className='transition-transform duration-300 ease-in-out transform hover:scale-105' alt="Restaurant 1" />*/}
                        {/*        <div className="align-center">*/}
                        {/*            <h3>Havey Restaurant</h3>*/}
                        {/*            <span>❤️</span>*/}
                        {/*        </div>*/}
                        {/*        <div className="align-center">*/}
                        {/*            <p style={{ fontSize: "12px" }}>30-50min</p>*/}
                        {/*            <span>⭐ 4.5</span>*/}
                        {/*        </div>*/}
                        {/*    </Card>*/}
                        {/*</Col>*/}

                    </Row>
                </div>


                {/* MOBILE SCREEN */}
                <div className="md:hidden px-4">
                    {!restaurants || restaurants.length === 0 ? (
                        <div className="text-center">
                            <img src={oops} width={200} className="mx-auto" alt="oops" />
                            <h3 className="mt-4 text-gray-600">No Restaurant Found in your selected city</h3>
                        </div>
                    ) : (
                        <div className="grid grid-cols-2 gap-4">
                            {restaurants.map((restaurant) => (
                                <div
                                    key={restaurant.id}
                                    className="bg-white p-3 rounded-lg shadow-md cursor-pointer"
                                    onClick={() => navigate(`/restaurant/${restaurant.id}`)}
                                >
                                    <img
                                        src={restaurant.display_image}
                                        alt={restaurant.name}
                                        className="w-full h-44 object-cover rounded-md transition-transform duration-300 ease-in-out transform hover:scale-105"
                                    />
                                    <div className="mt-2">
                                        <h3 className="font-bold text-xl truncate">{restaurant.name}</h3>
                                        <div className="flex justify-between items-center mt-1  text-gray-500">
                                            <p>{`${restaurant.min_ready_time} mins`}</p>
                                            <span className="text-yellow-500">⭐ {restaurant.rating || 'N/A'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>



            </div>
        // </div>
    )
}
