import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, notification } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import defaultBg from "../../assets/images/restaurant/defaultRestaurantMenu.jpeg";
import { useDispatch } from "react-redux";
import { addToCart } from "../../slices/restaurantCartSlice";
import NumberFormat from "react-number-format";
import RightDrawerTemplate from "../RightDrawerTemplate";

export const FoodCard = ({ item }) => {
    const discount = parseFloat(item.discount || 0);
    const originalPrice = parseFloat(item.public_price || 0);
    // const discountedPrice = discount > 0 ? (originalPrice * (1 - discount / 100)).toFixed(2) : originalPrice.toFixed(2);
    const dispatch = useDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [selectedExtras, setSelectedExtras] = useState([]);
    const [extraQuantities, setExtraQuantities] = useState({});



    const handleExtraQuantityChange = (extraId, increment) => {
        setExtraQuantities((prevQuantities) => ({
            ...prevQuantities,
            [extraId]: Math.max(1, (prevQuantities[extraId] || 0) + increment),
        }));
    };

    const calculateTotalPrice = () => {
        const extrasPrice = selectedExtras.reduce((total, extra) => {
            return total + extra.price * (extraQuantities[extra.id] || 1);
        }, 0);
        return quantity * originalPrice + extrasPrice;
    };

    const handleCheckboxChange = (extraId, price, name) => {
        setSelectedExtras((prev) => {
            const isSelected = prev.find((extra) => extra.id === extraId);
            if (isSelected) {
                // Remove extra if already selected
                setExtraQuantities((prevQuantities) => {
                    const newQuantities = { ...prevQuantities };
                    delete newQuantities[extraId];
                    return newQuantities;
                });
                return prev.filter((extra) => extra.id !== extraId);
            } else {
                // Add extra if not selected
                setExtraQuantities((prevQuantities) => ({
                    ...prevQuantities,
                    [extraId]: 1, // Default to 1 when first selected
                }));
                return [
                    ...prev,
                    { id: extraId, price, item: { name } }, // Ensure `item` has a `name`
                ];
            }
        });
    };

    const handleAddToCart = () => {
        const extrasWithQuantities = selectedExtras.map((extra) => ({
            ...extra,
            quantity: extraQuantities[extra.id],
        }));

        dispatch(
            addToCart({
                productId: item.id,
                quantity,
                public_price: parseFloat(item.public_price),
                discount: parseFloat(item.discount),
                product_name: item.name,
                extras: extrasWithQuantities, // Add extras with name included
            })
        );
        setIsDrawerOpen(false);
    };

    const handleDrawerOpen = () => setIsDrawerOpen(true);
    const handleDrawerClose = () => setIsDrawerOpen(false);

    return (
        <>
            <Card
                hoverable
                className="max-w-xs rounded-xl shadow-lg overflow-hidden rest_card_body border-2 hover:border-black"
                cover={<img className="object-cover" src={item.images[0]?.url || defaultBg} alt={item.name} />}
                onClick={handleDrawerOpen}
            >
                <div className="p-4">
                    <h3 className="font-bold text-2xl">{item.name}</h3>
                    <div className="flex justify-between flex-wrap items-center mt-4">
                        {/*{discount > 0 && (*/}
                        {/*    <span className="text-gray-400 line-through">*/}
                        {/*        <NumberFormat*/}
                        {/*            value={originalPrice}*/}
                        {/*            displayType={"text"}*/}
                        {/*            thousandSeparator={true}*/}
                        {/*            decimalScale={2}*/}
                        {/*            fixedDecimalScale={true}*/}
                        {/*            prefix={"₦"}*/}
                        {/*        />*/}
                        {/*    </span>*/}
                        {/*)}*/}
                        <span className="text-black font-bold">
                            <NumberFormat
                                value={originalPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"₦"}
                            />
                        </span>
                    </div>
                </div>
            </Card>
            {/* Drawer for detailed view */}
            <RightDrawerTemplate
                closeDrawer={handleDrawerClose}
                openDrawer={isDrawerOpen}
                width={450}
                title={item.name}
                sm={true}
            >
                <div className="flex flex-col gap-8 my-4 mt-8">
                    <div className="singleRestaurant_img flex">
                        <img
                            src={item.images[0]?.url || defaultBg}
                            alt={item.name}
                            className="w-full h-[200px] object-cover"
                        />
                    </div>
                    <div>
                        <p className="text-3xl text-[gray] font-gilroyBold ">Description</p>
                        <p>{item.description || "No description available"}</p>
                        <div className="w-full mt-8 flex justify-between items-center">
                            <p className="my-3 font-bold text-2xl">
                                Amount:
                                <NumberFormat
                                    value={originalPrice}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={" ₦"}
                                />
                            </p>
                            <div className="flex items-center justify_between amount_box py-1 px-6 rounded-2xl">
                                <Button
                                    shape="circle"
                                    size="small"
                                    className="justify-center hover:text-black      flex items-center"
                                    icon={<MinusOutlined/>}
                                    onClick={() => setQuantity((q) => Math.max(1, q - 1))}
                                />
                                <span className="font-bold text-[15px]  ">{quantity}</span>
                                <Button
                                    shape="circle"
                                    size="small"
                                    className="justify-center hover:text-black   flex items-center"
                                    icon={<PlusOutlined/>}
                                    onClick={() => setQuantity((q) => q + 1)}
                                />
                            </div>
                        </div>

                    </div>
                    {/*// Inside the render function of the Drawer, modify the Checkbox to pass the `name`*/}
                    {item.extras.length > 0 ? (
                        <div className="w-full bg-[#FDF4D7] rounded-2xl p-6 border-black border">
                            <p className="font-semibold text-black text-2xl mb-4">Select Options</p>
                            <div className="w-full flex flex-col gap-4">
                                {item.extras.map((extra) => {
                                    if (!extra.item) return null;
                                    return (
                                        <div key={extra.id} className="flex items-center justify-between">
                                            <Checkbox
                                                className="foodCard_checkbox"
                                                value={extra.item.id}
                                                onChange={() =>
                                                    handleCheckboxChange(
                                                        extra.item.id,
                                                        extra.item.public_price,
                                                        extra.item.name // Pass the name of the extra here
                                                    )
                                                }
                                            >
                                                <span>{extra.item.name}</span>
                                            </Checkbox>
                                            {selectedExtras.find((selected) => selected.id === extra.item.id) && (
                                                <div className="flex items-center gap-2">
                                                    <Button
                                                        shape="circle"
                                                        size="small"
                                                        className="justify-center flex items-center circle_btn "
                                                        icon={<MinusOutlined/>}
                                                        onClick={() => handleExtraQuantityChange(extra.item.id, -1)}
                                                    />
                                                    <span
                                                        className="font-bold text-xl">{extraQuantities[extra.item.id] || 1}</span>
                                                    <Button
                                                        shape="circle"
                                                        size="small"
                                                        className="justify-center flex items-center circle_btn"
                                                        icon={<PlusOutlined/>}
                                                        onClick={() => handleExtraQuantityChange(extra.item.id, 1)}
                                                    />
                                                </div>
                                            )}
                                            <span className="font-bold text-2xl">
                                                <NumberFormat
                                                    value={extra.item.public_price}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    prefix={"₦"}
                                                />
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ) : (
                        <p className="text-gray-500">No extras available for this item.</p>
                    )}
                    <div className="w-full flex justify-end px-4 items-center">
                        <Button
                            size="large"
                            onClick={handleAddToCart}
                            className="bg-[green] hover:bg-[#44843F] rounded-2xl border border-[green] text-white font-bold"
                        >
                            +
                            <NumberFormat
                                value={calculateTotalPrice()}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={" ₦"}
                            />
                        </Button>
                    </div>
                </div>
            </RightDrawerTemplate>
        </>

    );
};
