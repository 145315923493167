import React from 'react';
import '../../assets/css/_1.css';
import '../../assets/css/about.css';
import '../../assets/css/bills.css';
import '../../assets/css/wisenija.css';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import upgrade2_tier_2 from '../../assets/images/landing/Upgrade_tier2.png';
import instant_settlement from '../../assets/images/landing/Instant_settlement.png';
import shop_get from '../../assets/images/landing/Shop_get.png';
import recharge_get from '../../assets/images/landing/Recharge_get.png';
import dedicated_NUBAN from '../../assets/images/landing/Dedicated_NUBAN.png';
import transfer_fee from '../../assets/images/landing/transfer_fee.png';

import Wise9ja_1 from '../../assets/images/homepage/wise9ja1.png';
import Wise9ja_2 from '../../assets/images/homepage/wise9ja2.png';
import Wise9ja_3 from '../../assets/images/homepage/wise9ja3.png';
import { _get_cms_page, _get_profile } from '../../common/axios_services';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import { fetchData, profileData } from '../../slices/profileSlice';

const WhyBills = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token);
    let jwt_token = localStorage.getItem('konnect_token');
    const [allImages, setAllImages] = React.useState(false)

    const getProfile = async () => {
        try {
            const profile = await _get_profile();
            if (profile.data.status === "success") {
                dispatch(fetchData(true))
                dispatch(profileData(profile.data.data))
            } else {
                dispatch(fetchData(false))
                dispatch(profileData(false))
            }
        } catch (err) {
            dispatch(fetchData(false))
            dispatch(profileData(false))
        }
    }

    const getAllImages = async () => {
        try {
            const images = await _get_cms_page({ page: "wise9ja" })
            setAllImages(images.data.data)
        } catch (err) {
            // console.log(err);
        }
    }

    React.useEffect(() => {
        getAllImages()
    }, [])

    const findImageObj = ({ page }) => {
        return allImages.find(obj => obj["name"] === page)
    }

    React.useEffect(() => {
        getProfile();
    }, [dispatch, token, jwt_token])

    return (
        <div className="bill wise9ja minor">
            <Navbar />
            <div className="hero">
                <div className="contain">
                    <div className="grid_2">
                        <div>
                            <h1><span>Upgrade</span><br />to Tier 2 and <br />enjoy the following.</h1>
                            <p>Upgrade your account and let us spoil you a bit to live life on your own terms! Get richer each day when you refer, shop & keep cash aside for the future.</p>
                        </div>
                        <div style={{ height: "450px" }}>
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={upgrade2_tier_2} className="Upgrade to tier 2" style={{ width: 'auto', height: '80%' }} alt="header" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wise9ja-props">
                <div className="contain">
                    <div className="card-props-width">
                        <div className="card-list">
                            <div className="first-card card-prop-data">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Let your wallet do the magic</p>
                                    <h3 style={{ width: "100%" }}>Migrate to a dedicated NUBAN account.</h3>
                                    <p className="card-story">
                                        A dedicated NUBAN account number is created for your wallet to receive and transfer funds to any Nigerian bank. Your Tier 2 wallet is credited with your Tier 1 cleared balance less a fee.
                                    </p>
                                </div>
                                <div className="props-img" style={{ height: '25rem' }}>
                                    <img src={dedicated_NUBAN} className="prop-img" style={{ width: 'auto', height: '100%' }} alt="" />
                                </div>
                            </div>
                            <div className="second-card card-prop-data long-one">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Instant transfer</p>
                                    <h3 style={{ width: "100%" }}>Fund your account & can transfer the same day</h3>
                                    <p className="card-story">
                                        Fund your account and transfer to any beneficiary same day.
                                    </p>
                                </div>
                                <div className="props-img" style={{ height: '25rem' }}>
                                    <img src={instant_settlement} className="prop-img" style={{ width: 'auto', height: '100%' }} alt="" />
                                </div>
                            </div>
                            <div className="third-card card-prop-data">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">0% transfer fee.</p>
                                    <h3 style={{ width: "100%" }}>Zero percent transfer fee to wallet account</h3>
                                    <p className="card-story">Tier 2 user enjoys zero percent transfer fee when they transfer from their wallet to another wallet account.</p>
                                </div>
                                <div className="props-img" style={{ height: '25rem' }}>
                                    <img src={transfer_fee} className="prop-img" style={{ width: 'auto', height: '100%' }} alt="" />
                                </div>
                            </div>
                            <div className="fourth-card card-prop-data">
                                <div className="wise9ja-props-text">
                                    <p className="prop-title">Recharge and earn</p>
                                    <h3 style={{ width: "100%" }}>Recharge and get 8% cash bonus!</h3>
                                    <p className="card-story">You will get up to 8% cash bonus when you recharge your bills - airtime, data, TV and electricity.</p>
                                </div>
                                <div className="props-img" style={{ height: '25rem' }}>
                                    <img src={recharge_get} className="prop-img" style={{ width: 'auto', height: '100%' }} alt="" />
                                </div>
                            </div>
                        </div>
                        {/*<div className="long-card">*/}
                        {/*    <div className="grid-2">*/}
                        {/*        <div>*/}
                        {/*            <div>*/}
                        {/*                <p className="prop-title">Extra cash discount applies.</p>*/}
                        {/*                <h3 style={{ width: "100%" }}>Shop and get extra cash discount (food vendor only).</h3>*/}
                        {/*                <p className="card-story">Food vendors are users that buys food stuff in bulk and sell cooked food from a venue, online or from our platform. They get extra cash discount when they shop.</p>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div>*/}
                        {/*            <div className="props-img" style={{ height: '25rem' }}>*/}
                        {/*                <img src={shop_get} className="prop-img" style={{ width: 'auto', height: '100%' }} alt="" />*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", marginTop: "40px" }}>
                <button className="bg_side_green" onClick={() => navigate('/profile/complete-kyc')} style={{ fontSize: "2.3rem", margin: "0px auto 0px auto", fontWeight: "600" }}>CONTINUE <ion-icon name="arrow-forward-circle-outline"></ion-icon></button>
            </div>
            <div className="final-wise">
                <div className="contain">
                    <div className="grid">
                        <div className="grid-3">
                            <div>
                                <img src={Wise9ja_1} alt="signup" />
                                <h4>Quick signup</h4>
                                <p>Easily open a free account in few minutes.</p>
                            </div>
                            <div>
                                <img src={Wise9ja_2} alt="security" />
                                <h4>Top-tier security</h4>
                                <p>Depend on top-notch bank-level security for all your transactions.</p>
                            </div>
                            <div>
                                <img src={Wise9ja_3} alt="support" />
                                <h4>Reliable support</h4>
                                <p>Get access to reliable support and resources at your convenience.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile">
                <Footer noSpace={true} />
            </div>
            <div className="desktop show-all">
                <Footer />
            </div>
        </div>
    )
}

export default WhyBills