import React, {useEffect, useState} from 'react';
import Navbar from '../../components/Navbar';
import {Card, Col, notification, Row} from 'antd';
import {CheckCircleOutlined, CloseCircleOutlined, StarFilled, StarOutlined} from '@ant-design/icons';
import map_locationIcon from '../../assets/images/restaurant/map_locationIcon.svg';
import { UnderConstructionModal } from '../../components/UnderConstructionModal';
import {useNavigate, useParams} from "react-router-dom";
import {_get_single_restaurant_order_details} from "../../common/axios_services";

const TrackOrder = () => {
    const navigate = useNavigate();
    const {id: orderId} = useParams();
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [order, setOrder] = useState({});
    const [orderitems, setOrderItems] = useState([]);
    const [confirmationPin, setConfirmationPin] = useState('');
    const [trackId, setTrackId] = useState('');
    const [orderHistory, setOrderHistory] = useState({})



    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            description: message,
        });
    };


    const getOrderHist = async () => {
        try {
            setSpinnerLoading(true);
            const get_hist = await _get_single_restaurant_order_details(orderId);
            const responseData = get_hist.data.data;
            setOrderHistory(get_hist.data.data)

            setOrder(responseData.order);
            setOrderItems(responseData.items);
            setConfirmationPin(get_hist.data.data.order.confirmationPin);
            setTrackId(get_hist.data.data.order.track_id);

            console.log("I am from checkout successfull", get_hist.data);

        }
        catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    localStorage.removeItem('konnect_token')
                    navigate('/signin')
                }
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        } finally {
            setSpinnerLoading(false);
        }
    }

    useEffect(() => {
        getOrderHist()
    }, []);

  return (
    <div>
        <div>
            <Navbar noShadow={true} adjustNavSize={true} />
            <div className='mt-10'>
                <Row justify={'center'}>
                    <Col xs={24}>
                        <div className="flex mx-auto">
                        <h2 className="lg:text-7xl text-5xl mx-auto">Track delivery - #{trackId}</h2>
                        </div>
                    </Col>
                </Row>
                <div className="flex justify-center items-center my-10 ">
                    <div className='lg:w-1/2 w-full rounded-2xl border border-black py-5 px-10 '>
                        <Card
                            className='py-16 mb-5 flex justify-center items-center rounded-3xl border-2 border-black bg-[#03BFA6] '
                            bordered={false}>
                            <div className='h-full flex flex-col justify-center gap-y-3 mx-auto'>
                                {
                                    order.status === "confirmed" || order.status === "accepted" || order.status === "ready" ? (
                                            <CheckCircleOutlined className={"flex justify-center"}
                                                                 style={{fontSize: '32px', color: 'white'}}/>
                                        ) :
                                        order.status === "rejected" ? (
                                            <CloseCircleOutlined className={"flex justify-center"}
                                                                 style={{fontSize: '32px', color: 'red'}}/>
                                        ) : ""
                                }

                                {
                                    (order.status === "confirmed" || order.status === "accepted") ? (
                                            <>
                                                <h2 className='text-3xl text-center text-white '>Your order has
                                                    been {order.status}!</h2>
                                                {
                                                    order.status === "confirmed" ?
                                                        <p className={'text-center text-white mb-5 '}>Waiting for the Order
                                                            to be Accepted</p>
                                                        :
                                                        <p className={'text-center text-white mb-5 '}>Waiting for the Order
                                                            to be Ready</p>
                                                }
                                            </>
                                        ) :
                                        order.status === "ready" ? (
                                                <h2 className='text-3xl text-center text-white '>Your order is ready!,
                                                    Please go and Pickup your Order</h2>
                                            ) :
                                            order.status === "rejected" ? (
                                                    <h2 className='text-3xl text-center text-white '>Your order was
                                                        rejected/canceled and money refunded. Would you like to place an
                                                        order from another restaurant?</h2>
                                                ) :
                                                ""

                                }
                            </div>
                            {
                                (order.status === "accepted" || order.status === "ready") && (
                                    <p className='text-center text-white'>
                                        Give the restaurant the delivery code to receive your order
                                    </p>
                                )
                            }

                            <div>
                                {
                                    order.status === "picked" && (
                                        <p className={"text-white text-3xl mb-5 text-center"}>Thank you for ordering
                                            from {orderHistory.restaurant.name} </p>
                                    )
                                }
                            </div>
                            <p className='text-center text-[15px] text-white'>Rate the restaurant</p>
                            <div className="flex justify-center  items-center gap-3">
                                <StarFilled style={{fontSize: '32px', color: '#FFF500'}}/>
                                <StarFilled style={{fontSize: '32px', color: '#FFF500'}}/>
                                <StarFilled style={{fontSize: '32px', color: '#FFF500'}}/>
                                <StarFilled style={{fontSize: '32px', color: '#FFF500'}}/>
                                <StarOutlined style={{fontSize: '32px', color: '#fff'}}/>
                            </div>
                        </Card>
                        {
                            (order.status === "accepted" || order.status === "ready") && (
                                <Row justify={'center'} gutter={{lg: 32}} className='lg:mt-16  '>
                                    <Col xs={24} lg={14}>
                                        <Card bordered={false}
                                              className='w-full max-sm:mb-5 py-16 px-5 rounded-3xl border-2 border-black bg-[#EAF1E9] '>
                                            <div className='h-full w-full '>
                                                {/*<h2 className="text-3xl text-[#44843F] ">Delivery to</h2>*/}
                                                <h2 className="text-3xl text-[#44843F] ">Pickup at</h2>
                                                {/*<div className="flex flex-wrap  gap-3 text-black mt-3">*/}
                                                {/*    <p className='text-2xl'>John Doe</p>*/}
                                                {/*    <p className='text-2xl font-semibold '>+234 800 123 4567</p>*/}
                                                {/*    <p className='text-2xl'>johndoe@gmail.com</p>*/}
                                                {/*</div>*/}
                                                <div className="flex items-center gap-3 text-black mt-3">
                                            <span>
                                                <img src={map_locationIcon} alt="" className='w-10 h-10'/>
                                            </span>
                                                    <p className='text-2xl font-bold'>{orderHistory.delivery_address}</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs={24} lg={10}>
                                        <Card bordered={false} className='w-full max-sm:my-6   py-16 px-5 rounded-3xl border-2 border-black bg-[#E9C6C66C] '>
                                            <div className='h-full w-full' >
                                                {/*<p className='font-bold' >Give rider this code after delivery</p>*/}
                                                <p className='font-bold' >Give restaurant this code at pickup</p>
                                                <h2 className="text-4xl my-3 text-[#44843F]">{confirmationPin}</h2>


                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }
                    </div>
                </div>
            
            </div>


        </div>
        
    </div>
  )
}

export default TrackOrder