import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, message, Modal, notification, Select, Skeleton, Spin, Tooltip } from "antd";
import { default as React, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from "react-redux";
import { default as Navbar } from '../components/Navbar';
import Profile from '../components/dashboard/side_nav';
import { fetchData, profileData } from "../slices/profileSlice";

import Airtime from '../assets/images/icons/airtime.png';
import Cable from '../assets/images/icons/cable.png';
import TV from '../assets/images/icons/tv.png';

import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import PayBillsHero from '../assets/images/homepage/bill.png';
import TablerCash from '../assets/images/icons/tabler_cash.png';
import { _buy_airtime, _buy_data, _buy_electricity, _buy_tv, _get_bill_packages, _get_bill_providers, _get_bills, _get_profile, _get_user_wallet_account, _validate_cards, _verify_pin } from "../common/axios_services";

const ProfileBills = () => {
    const currency = "NGN"
    const [verifyForm] = Form.useForm();
    const [form] = Form.useForm();
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [allBills, setAllBills] = useState([]);
    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#154C9A' }} spin />;
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const token = useSelector(state => state.userProfile.token)
    let jwt_token = localStorage.getItem('konnect_token')

    const [ServiceId, setServiceId] = useState("")
    const [identifier, setIdentifier] = useState("")
    const [visible, setVisible] = useState(false)
    const [errMsg, setErrMsg] = useState(false)
    const [validCard, setValidCard] = useState(false)
    const [variations, setVariations] = useState(false)
    const [subType, setSubType] = useState(false)
    const [providers, setProviders] = useState([])
    const [packages, setPackages] = useState([])
    const [validPhone, setValidPhone] = useState(false)
    const [wallet_bal, setWallet_bal] = useState(false)
    const [searchParams, setSearchParams] = useState("");
    const [currentService, setCurrentService] = useState('');
    const [providerSelected, setProviderSelected] = useState(false);
    const [packageSelected, setPackageSelected] = useState(false);
    const [selectedPackageSlug, setSelectedPackageSlug] = useState(false);
    const [subscribing, setSubscribing] = useState(false);
    const [validatingCard, setValidatingCard] = useState(false);
    const [validatingError, setValidatingError] = useState(false);
    const [meterModal, setMeterModal] = useState(false);
    const [electricityData, setElectricityData] = useState(false);
    const [verifingPin, setVerifingPin] = useState(false);
    const [verifyModal, setVerifyModal] = useState(false);

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : "",
            description: message ? message : "",
            placement: "bottom-right"
        });
    };
    const getBills = async () => {
        try {
            const bills = await _get_bills()
            setAllBills(bills?.data?.data)
        } catch (err) {
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.message)
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getProfile = async () => {
        try {
            const user_profile = await _get_profile()
            dispatch(profileData(user_profile.data.data))
            dispatch(fetchData(true))
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    dispatch(profileData(false))
                    dispatch(fetchData(false))
                    navigate("/signin?redir=review")
                    localStorage.removeItem('konnect_token')
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                } else {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message)
                }
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message)
            }
        }
    }
    const getIcon = (slug) => {
        switch (slug) {
            case 'airtime':
                return Airtime;
            case 'data':
                return Cable;
            case 'television':
                return TV;
            case 'electricity':
                return Cable;
            default:
                return null;
        }
    };

    const getWalletAccount = async () => {
        try {
            let fetchAccounts = await _get_user_wallet_account();
            setWallet_bal(fetchAccounts?.data?.data);
        } catch (err) {
            setWallet_bal({});
            openNotificationWithIcon('error', 'An error occurred while loading wallet balance. Please reload page to try again')
        }
    }

    React.useEffect(() => {
        getProfile()
        getBills()
        getWalletAccount()
    }, [dispatch, token, jwt_token, searchParams])

    let skeleton = [];
    for (let i = 0; i < 6; i++) {
        skeleton.push(<Skeleton active />)
    }

    const profile = useSelector(state => state.userProfile.dataFetched)

    const getProviders = async ({ slug }) => {
        try {
            const get_providers = await _get_bill_providers({ slug })
            setProviders(get_providers?.data?.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                    setErrMsg(false)
                } else {
                    setErrMsg(err.response.data.message);
                }
            } else {
                setErrMsg("")
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const getBillPackage = async ({ id }) => {
        setPackages([])
        try {
            const get_bill_packages = await _get_bill_packages({ id })
            setPackages(get_bill_packages?.data?.data)
        } catch (err) {
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                    setErrMsg(false)
                } else {
                    setErrMsg(err.response.data.message);
                }
            } else {
                setErrMsg("")
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const openModal = async ({ service }) => {
        setCurrentService(service.name);
        setIdentifier(service.slug);
        setVerifyModal(true);
        setSubType(service.product_type);
        getProviders({ slug: service.slug })
        form.setFieldsValue({ card_tv: service.service_id });
    }

    const handleBillerChange = (value) => {
        const selectedProvider = providers.find(biller => biller.attributes.name === value)
        setProviderSelected(true)
        getBillPackage({ id: selectedProvider?.id })
        form.setFieldsValue({
            package: ''
        })
    };

    const validatePackage = (value) => {
        const selectedPackage = packages.find(billPackage => billPackage.attributes.slug === value)
        return selectedPackage;
    };

    const validateCards = async ({ customer_id, product_slug }) => {
        setValidatingError(false)
        setValidatingCard(true)
        setValidCard(false)
        try {
            const validate_card = await _validate_cards({ customer_id, product_slug })
            setValidatingCard(false)
            setValidatingError(false)
            setValidCard(validate_card?.data?.data)
        } catch (err) {
            setValidCard(false)
            if (err.response) {
                setValidatingError(err.response.data.message)
                if (err.response.data.message === "unauthorized") {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                    navigate('/signin?redir=bills')
                } else {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                }
            } else {
                setValidatingError(err.message)
                openNotificationWithIcon('error', 'Something went wrong', err.message)
            }
        } finally {
            setValidatingCard(false)
        }
    }

    const onchangeDataValues = (values) => {
        if (values.phone) {
            let phone = `${values.phone}`
            if (phone.length > 8) {
                setValidPhone(true)
            }
        }
        if (values.package) {
            let selectedPackage = validatePackage(values.package)
            form.setFieldsValue({
                amount: `${(selectedPackage?.attributes?.price?.maximumAmount) / 100}`
            })
        }
    }

    const onChangeTv = (values) => {
        if (values.package) {
            setPackageSelected(true);
            setSelectedPackageSlug(values.package)
            let selectedPackage = validatePackage(values.package)
            form.setFieldsValue({
                amount: `${(selectedPackage?.attributes?.price?.maximumAmount) / 100}`
            })
        }
        if (values.smart_card) {
            let smart_card = `${values.smart_card}`
            if (smart_card.length > 9) {
                validateCards({ customer_id: smart_card, product_slug: selectedPackageSlug })
            } else {
                setValidCard(false)
            }
        }
    }

    const onchangeElectricity = (values) => {
        if (values.package) {
            setPackageSelected(true);
            setSelectedPackageSlug(values.package)
        }
        if (values.meter_no) {
            let meter_no = `${values.meter_no}`
            if (meter_no.length > 9) {
                validateCards({ customer_id: meter_no, product_slug: selectedPackageSlug })
            } else {
                setValidCard(false)
            }
        }
    }

    const buyAirtime = async (values) => {
        const data = {
            provider: `${values.provider}`,
            amount: `${values.amount}`,
            phone_number: `${values.phone}`
        }
        setSubscribing(true)
        setErrMsg(false)
        try {
            const buy_airtime = await _buy_airtime(data)
            setErrMsg(false)
            openNotificationWithIcon('success', buy_airtime?.data?.title, buy_airtime?.data?.message)
            closeModal()
        } catch (err) {
            if (err.response) {
                setErrMsg(err.response.data.message)
                if (err.response.data.message === "unauthorized") {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                    navigate('/signin?redir=bills')
                } else {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                }
            } else {
                setErrMsg(err.message)
                openNotificationWithIcon('error', 'Something went wrong', err.message)
            }
        } finally {
            setSubscribing(false)
        }
    }

    const buyData = async (values) => {
        const data = {
            provider: `${values.provider}`,
            product_slug: `${values.package}`,
            amount: `${(validatePackage(values.package)?.attributes?.price?.maximumAmount) / 100}`,
            phone_number: `${values.phone}`
        }
        setSubscribing(true)
        setErrMsg(false)
        try {
            const buy_data = await _buy_data(data)
            setErrMsg(false)
            openNotificationWithIcon('success', buy_data?.data?.title, buy_data?.data?.message)
            closeModal()
        } catch (err) {
            if (err.response) {
                setErrMsg(err.response.data.message)
                if (err.response.data.message === "unauthorized") {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                    navigate('/signin?redir=bills')
                } else {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                }
            } else {
                setErrMsg(err.message)
                openNotificationWithIcon('error', 'Something went wrong', err.message)
            }
        } finally {
            setSubscribing(false)
        }
    }

    const buyTV = async (values) => {
        const data = {
            provider: `${values.provider}`,
            product_slug: `${values.package}`,
            smartcard_number: `${values.smart_card}`,
            amount: `${(validatePackage(values.package)?.attributes?.price?.maximumAmount) / 100}`,
            phone_number: `${values.phone}`
        }
        setSubscribing(true)
        setErrMsg(false)
        try {
            const buy_tv = await _buy_tv(data)
            setErrMsg(false)
            openNotificationWithIcon('success', buy_tv?.data?.title, buy_tv?.data?.message)
            closeModal()
        } catch (err) {
            if (err.response) {
                setErrMsg(err.response.data.message)
                if (err.response.data.message === "unauthorized") {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                    navigate('/signin?redir=bills')
                } else {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                }
            } else {
                setErrMsg(err.message)
                openNotificationWithIcon('error', 'Something went wrong', err.message)
            }
        } finally {
            setSubscribing(false)
        }
    }

    const buyElectricity = async (values) => {
        const data = {
            provider: `${values.provider}`,
            product_slug: `${values.package}`,
            meter_account_number: `${values.meter_no}`,
            amount: `${values.amount}`,
            phone_number: `${values.phone}`
        }
        setSubscribing(true)
        setErrMsg(false)
        try {
            const buy_electricity = await _buy_electricity(data)
            setErrMsg(false)
            openNotificationWithIcon('success', buy_electricity?.data?.title, buy_electricity?.data?.message)
            setElectricityData(buy_electricity?.data)
            setMeterModal(true)
            closeModal()
        } catch (err) {
            if (err.response) {
                setErrMsg(err.response.data.message)
                if (err.response.data.message === "unauthorized") {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                    navigate('/signin?redir=bills')
                } else {
                    openNotificationWithIcon('error', err.response.data.title, err.response.data.message)
                }
            } else {
                setErrMsg(err.message)
                openNotificationWithIcon('error', 'Something went wrong', err.message)
            }
        } finally {
            setSubscribing(false)
        }
    }

    const buttonStyle = {
        background: '#F2F4FA',
        padding: '12px 5px',
        borderRadius: '16px',
        border: '1px solid #F2F4FA',
        display: 'flex',
        flexDirection: 'column',
        gridGap: '10px',
        color: '#000000',
        outline: 'none',
    };

    const closeModal = () => {
        setVisible(false);
        form.resetFields()
        setPackages([])
        setProviders([])
        setVariations(false)
        setServiceId("")
        setValidCard(false)
    }

    const closeMeterModal = () => {
        setMeterModal(false)
    }

    const handleCopy = () => {
        message.success('Electricity Token copied to clipboard');
    };

    const VerifyPin = async (values) => {
        setVerifingPin(true)
        try {
            const verify_pin = await _verify_pin({ pin: values.pin });
            setVerifingPin(false);
            openNotificationWithIcon('success', verify_pin?.data?.title, verify_pin?.data?.message);
            setErrMsg(false)
            setVerifyModal(false)
            setVisible(true)
            verifyForm.resetFields()
        } catch (err) {
            setVerifingPin(false)
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    openNotificationWithIcon(err?.response?.data?.status, err?.response?.data?.title, err?.response?.data?.message);
                    navigate('/signin')
                    setErrMsg(false)
                } else {
                    setErrMsg(err.response.data.message);
                }
            } else {
                setErrMsg(false)
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }
    return (
        <div>
            <div className="userprofile">
                <div className="profile-content">
                    <Spin spinning={spinnerLoading} indicator={newAntIcon}>
                        <Navbar />
                        <div className="gray_bg_profile">
                            <div className="profile_to_left">
                                <div className="">
                                    <div className="profile_nav">
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                            <div className="profile_to_right">
                                <div className="">
                                    <div className="contain">
                                        <div className="main_container">
                                            <div className="slider_div_to_top">
                                                <div className="item grid-4-box colored color_5 loyalty_card">
                                                    <div className="slick-div">
                                                        <div className="slick-box">
                                                            <div className="icon-box">
                                                                <img src={TablerCash} alt="tabler cash" />
                                                            </div>
                                                            <div className="slider-main-content">
                                                                <p>Konnect wallet</p>
                                                                <h2>
                                                                    <span style={{ marginRight: "3px" }} className="currency">{currency}</span>
                                                                    <NumberFormat thousandSeparator={true} displayType={'text'} value={wallet_bal?.available_balance || 0} />
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hero">
                                                <div className="contain">
                                                    <div className="grid_2">
                                                        <div className="hero_grid_block">
                                                            <h1>Pay your bills! <br /> With Ease Anytime.</h1>
                                                            {/* <p>See below the service provider you can pay for</p> */}
                                                            <p>
                                                                Konnect makes life easy for all, use your bonuses to pay for
                                                                bills or fund your wallet with ease. Select your service provider as listed below.
                                                            </p>
                                                            <div>
                                                                <div className="icons-list">
                                                                    {allBills
                                                                        .filter((service) => service.name === 'Airtime' || service.name === 'Data') // Filter Airtime and Data
                                                                        .map((service, index) => (
                                                                            <button key={index} style={buttonStyle}
                                                                                    onClick={() => openModal({service})}>
                                                                                <div className={`icon-cover tv`}
                                                                                     style={{margin: "auto"}}>
                                                                                    <img
                                                                                        style={{
                                                                                            width: '20px',
                                                                                            height: '20px'
                                                                                        }}
                                                                                        src={getIcon(service.slug)}
                                                                                        alt={service.name}
                                                                                    />
                                                                                </div>
                                                                                <p>{service.name}</p>
                                                                            </button>
                                                                        ))}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="hero_grid_block">
                                                            <img src={PayBillsHero} className="wise9ja_header"
                                                                 alt="header"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <Modal open={visible} centered onOk={() => setVisible(false)} maskClosable={true} onCancel={() => closeModal()} footer={null} >
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <h4 className="modal-title">Buy {identifier} &mdash; {currentService}</h4>
                    {/* <p className="modal-des">You currently have <span style={{ fontSize: "15px", fontWeight: "bold" }}>{currency && `${currency}`} {wallet_bal ? (<NumberFormat thousandSeparator={true} displayType={'text'} value={wallet_bal} />) : `NGN0.00`} </span> in your balance.<br />Click Complete Payment to use this amount to recharge for FREE and complete this transaction. Also visit Wise9ja to earn FREE cash.</p> */}
                    <div style={{ marginTop: '5%' }}></div>
                    {errMsg && <p style={{ fontSize: "1rem", color: "red", marginBottom: '1rem' }}>{errMsg}</p>}
                    {identifier === "airtime" &&
                        <Form form={form} onFinish={buyAirtime} layout="vertical">
                            <Form.Item name="provider" label="Select Provider" rules={[{ required: true, message: 'Please select a provider' }]}>
                                <Select onChange={handleBillerChange} placeholder="Select a provider">
                                    {providers.map(biller => (
                                        <Select.Option key={biller?.id} value={biller?.attributes?.name}>{biller?.attributes?.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {/* {providerSelected && (
                                <Form.Item name="package" label="Select Package" rules={[{ required: true, message: 'Please select a Package' }]}>
                                    <Select placeholder="Select a package">
                                        {packages.map(billPackage => (
                                            <Select.Option key={billPackage?.id} value={billPackage?.attributes?.slug}>{billPackage?.attributes?.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )} */}
                            <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please input your phone number' }]}>
                                <Input type="tel" style={{ width: "100%", height: '5rem' }} placeholder="Phone Number" />
                            </Form.Item>
                            <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input an amount' }]}>
                                <InputNumber controls={false} style={{ width: "100%" }} size="large" placeholder="Amount" />
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => closeModal()}>Cancel</Button>
                                {subscribing ?
                                    <Button loading disabled type="primary">Subscribing...</Button> :
                                    <Button htmlType="submit" type="primary" style={{ background: "green" }}>Subscribe</Button>
                                }
                            </div>
                        </Form>
                    }
                    {identifier === "data" &&
                        <Form form={form} onValuesChange={onchangeDataValues} onFinish={buyData} layout="vertical">
                            <Form.Item name="provider" label="Select Provider" rules={[{ required: true, message: 'Please select a provider' }]}>
                                <Select onChange={handleBillerChange} placeholder="Select a provider">
                                    {providers.map(biller => (
                                        <Select.Option key={biller?.id} value={biller?.attributes?.name}>{biller?.attributes?.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {providerSelected && (
                                <Form.Item name="package" label="Select Package" rules={[{ required: true, message: 'Please select a Package' }]}>
                                    <Select placeholder="Select a package">
                                        {packages.map(billPackage => (
                                            <Select.Option key={billPackage?.id} value={billPackage?.attributes?.slug}>{billPackage?.attributes?.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            <Form.Item name="amount" label="Amount" >
                                <Input disabled style={{ width: "100%", height: '5rem' }} placeholder="Amount" />
                            </Form.Item>
                            <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please input your phone number' }]}>
                                <Input type="tel" style={{ width: "100%", height: '5rem' }} placeholder="Phone Number" />
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => closeModal()}>Cancel</Button>
                                {subscribing ?
                                    <Button loading disabled type="primary">Subscribing...</Button> :
                                    <Button htmlType="submit" type="primary" style={{ background: "green" }}>Subscribe</Button>
                                }
                            </div>
                        </Form>
                    }
                    {identifier === "television" &&
                        <Form form={form} onFinish={buyTV} onValuesChange={onChangeTv} layout="vertical">
                            <Form.Item name="provider" label="Select Provider" rules={[{ required: true, message: 'Please select a provider' }]}>
                                <Select onChange={handleBillerChange} placeholder="Select a provider">
                                    {providers.map(biller => (
                                        <Select.Option key={biller?.id} value={biller?.attributes?.name}>{biller?.attributes?.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {providerSelected && (
                                <Form.Item name="package" label="Select Package" rules={[{ required: true, message: 'Please select a Package' }]}>
                                    <Select placeholder="Select a package">
                                        {packages.map(billPackage => (
                                            <Select.Option key={billPackage?.id} value={billPackage?.attributes?.slug}>{billPackage?.attributes?.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {packageSelected && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: "space-between", marginBottom: '1rem', padding: '0rem 0.5rem' }}>
                                        <label>Smartcard number</label>
                                        {validatingCard ? <Spin indicator={newAntIcon} /> : <> {validCard && <p style={{ margin: 0 }}>{validCard?.attributes?.customerName}</p>}</>}
                                    </div>
                                    <Form.Item name="smart_card" rules={[{ required: true, message: 'Please input your smart card number' }]}>
                                        <Input style={{ width: "100%", height: '5rem' }} placeholder="Smartcard number" />
                                    </Form.Item>
                                </>
                            )}
                            {validatingError && <p style={{ fontSize: "1rem", color: "red", marginBottom: '1rem' }}>{validatingError}</p>}
                            <div className="form-flex-2">
                                <Form.Item name="amount" label="Amount" >
                                    <Input disabled style={{ width: "100%", height: '5rem' }} placeholder="Amount" />
                                </Form.Item>
                                <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please input your phone number' }]}>
                                    <Input type="tel" style={{ width: "100%", height: '5rem' }} placeholder="Phone Number" />
                                </Form.Item>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => closeModal()}>Cancel</Button>
                                {validCard ?
                                    <>
                                        {subscribing ?
                                            <Button loading disabled>Subscribing...</Button> :
                                            <Button htmlType="submit" type="primary" style={{ background: "green" }}>Subscribe</Button>
                                        }
                                    </>
                                    :
                                    <Button disabled>Subscribe</Button>
                                }
                            </div>
                        </Form>
                    }
                    {identifier === "electricity" &&
                        <Form form={form} onFinish={buyElectricity} onValuesChange={onchangeElectricity} layout="vertical">
                            <Form.Item name="provider" label="Select Provider" rules={[{ required: true, message: 'Please select a provider' }]}>
                                <Select onChange={handleBillerChange} placeholder="Select a provider">
                                    {providers.map(biller => (
                                        <Select.Option key={biller?.id} value={biller?.attributes?.name}>{biller?.attributes?.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {providerSelected && (
                                <Form.Item name="package" label="Select Package" rules={[{ required: true, message: 'Please select a Package' }]}>
                                    <Select placeholder="Select a package">
                                        {packages.map(billPackage => (
                                            <Select.Option key={billPackage?.id} value={billPackage?.attributes?.slug}>{billPackage?.attributes?.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                            {packageSelected && (
                                <>
                                    <div style={{ display: 'flex', alignItems: 'center', flex: 1, justifyContent: "space-between", marginBottom: '1rem', padding: '0rem 0.5rem' }}>
                                        <label>Meter number</label>
                                        {validatingCard ? <Spin indicator={newAntIcon} /> : <> {validCard && <p style={{ margin: 0 }}>{validCard?.attributes?.customerName}</p>}</>}
                                    </div>
                                    <Form.Item name="meter_no" rules={[{ required: true, message: 'Please input your meter number' }]}>
                                        <Input style={{ width: "100%", height: '5rem' }} placeholder="Meter number" />
                                    </Form.Item>
                                </>
                            )}
                            {validatingError && <p style={{ fontSize: "1rem", color: "red", marginBottom: '1rem' }}>{validatingError}</p>}
                            <Form.Item name="amount" label="Amount" >
                                <InputNumber controls={false} style={{ width: "100%" }} size="large" placeholder="Amount" />
                            </Form.Item>
                            <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please input your phone number' }]}>
                                <Input type="tel" style={{ width: "100%", height: '5rem' }} placeholder="Phone Number" />
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => closeModal()}>Cancel</Button>
                                {validCard ?
                                    <>
                                        {subscribing ?
                                            <Button loading disabled>Subscribing...</Button> :
                                            <Button htmlType="submit" type="primary" style={{ background: "green" }}>Subscribe</Button>
                                        }
                                    </>
                                    :
                                    <Button disabled>Subscribe</Button>
                                }
                            </div>
                        </Form>
                    }
                </div>
            </Modal>
            <Modal open={meterModal} centered onOk={() => setMeterModal(false)} maskClosable={false} footer={null} >
                <h4 className="modal-title">{electricityData?.message}</h4>
                <p className="modal-des">Please copy your electricity token and load it onto your prepaid meter. <b>Ensure you copy the token before closing this modal.</b> Thank you!</p>
                <div className='justify_between' style={{ marginTop: "10px" }}>
                    <p className="modal-desc">Electricity Token :</p>
                    <div style={{ display: "flex" }}>
                        <h4 className="modal-title">{electricityData?.data?.details?.detail?.token}</h4>
                        <span style={{ padding: "3px", background: "#b9feb9", borderRadius: "5px", marginLeft: "5px" }}>
                            <CopyToClipboard text={electricityData?.data?.details?.detail?.token} onCopy={handleCopy}>
                                <Tooltip title="Copy">
                                    <CopyOutlined style={{ cursor: 'pointer' }} />
                                </Tooltip>
                            </CopyToClipboard>
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                    <Button style={{ marginLeft: "auto" }} onClick={closeMeterModal}>Close</Button>
                </div>
            </Modal>
            <Modal centered open={verifyModal} onCancel={() => { setVerifyModal(false); verifyForm.resetFields() }} footer={null} >
                <div>
                    <h4 className="modal-title">Transaction Pin</h4>
                    {profile?.pin === "0000" ?
                        <p>Activate 2fa and create transaction pin</p> :
                        <p>Enter your transaction pin.</p>
                    }
                    <div style={{ marginTop: '5%' }}></div>
                    {errMsg ? <p className="error-message">{errMsg}</p> : ''}
                    <Form form={verifyForm} layout="vertical" style={{ maxWidth: 600 }} onFinish={VerifyPin} autoComplete="off">
                        <Form.Item label="Pin" name="pin">
                            {profile?.pin === "0000" ?
                                <Input.Password placeholder="Enter Pin" disabled />
                                :
                                <Input.Password placeholder="Enter Pin" autoComplete="off" />
                            }
                        </Form.Item>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            {profile?.pin === "0000" ?
                                <>
                                    <Button type='primary' onClick={() => { navigate(`/profile/overview?pin=${true}`) }}>Create Transaction Pin</Button>
                                </>
                                :
                                <>
                                    {verifingPin ?
                                        <Button type="primary" loading disabled>Verifing Pin</Button> :
                                        <Button type="primary" htmlType="submit">Verify Pin</Button>
                                    }
                                </>
                            }
                            <Button onClick={() => { setVerifyModal(false); verifyForm.resetFields() }} >Cancel</Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

export default ProfileBills