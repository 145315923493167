/* eslint-disable */

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Form, Input, Modal, Pagination, Result, Select, Spin, Switch, Table, Tabs, Tag, notification } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import Jwt_decrypt from '../../common/Jwt_decrypt';
import axiosCall from '../../common/axios_call';
import {
    _get_all_invoice,
    _get_all_orders,
    _get_all_restaurant_orders,
    _get_restaurant_order
} from '../../common/axios_services';
import Navbar from '../../components/Navbar';
import Profile from '../../components/dashboard/side_nav';
import Footer from '../../components/Footer';
import '../user/assets/index.css'


const OrderHistory = () => {
    const navigate = useNavigate()
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const params = new URLSearchParams(window?.location?.search);
    const [tx_ref,] = useState(params.get('tx_ref') ? params.get('tx_ref') : '');
    const [status,] = useState(params.get('status') ? params.get('status') : '');
    const [transaction_id,] = useState(params.get('transaction_id') ? params.get('transaction_id') : '');

    const newAntIcon = <LoadingOutlined style={{ fontSize: 24, color: '#008060' }} spin />;
    const profileData = useSelector(state => state.userProfile.dataFetched)
    const [acc_bal, setAcc_bal] = useState(0)
    const [order_his, setOrder_his] = useState([]);
    const [deliveredOrder, setDeliveredOrder] = useState([]);
    const [notDeliveredOrder, setNotDeliveredOrder] = useState([]);
    const [currency, setCurrency] = useState("NGN")
    const [wallet_bal, setWallet_bal] = useState(0)
    const [voucher_bal, setVoucher_bal] = useState(0)
    const [wise9ja_bal, setWise9ja_bal] = useState(0)
    const [loadingData, setLoadingData] = useState(true);
    const [page, setPage] = useState(1)
    const [per_page, setPer_page] = useState(10)
    const [total_trans, setTotal_trans] = useState(0)
    const [tableDisplay, setTableDisplay] = useState('all');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);



    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: "",
            description: message,
            placement: "bottom-right"
        });
    };
    const jwt = localStorage.konnect_token

    if (!jwt) {
        openNotificationWithIcon('error', 'Unauthorized')
        return navigate('/signin')
    }

    const token = Jwt_decrypt({ token: localStorage.konnect_token })

    const allTransOrders = async () => {
        try {
            const allOrders = await _get_all_restaurant_orders();
            if (allOrders?.data?.data) {
                setOrder_his(allOrders.data.data.data);
                setTotal_trans(allOrders.data.data.meta.total);
            } else {
                setOrder_his([]);
            }
        } catch (err) {
            console.error("Error loading orders:", err);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate("/signin?redir=review");
                    localStorage.removeItem('konnect_token');
                    openNotificationWithIcon('error', 'Session expired. Please sign in again.');
                } else {
                    openNotificationWithIcon('error', err.response.data.message);
                }
            } else {
                openNotificationWithIcon('error', err.message);
            }
        } finally {
            setLoadingData(false);
        }
    };



    useEffect(() => {
        allTransOrders();
        axiosCall(`account/details`, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
            setCurrency(res.data.data.currency)
            setAcc_bal(res.data.data.wallet_bal + res.data.data.voucher_bal)
            setWallet_bal(res.data.data.wallet_bal)
            setVoucher_bal(res.data.data.voucher_bal)
            setWise9ja_bal(res.data.data.wisenija_bal)
        }).catch(err => {
            openNotificationWithIcon('error', err.response.data.message)
        })
    }, [per_page, page, tx_ref, status, transaction_id])

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPer_page(pageSize);
    }

    // ACTION VIEW
    const handleViewClick = (record) => {
        setSelectedOrder(record);
        setIsModalVisible(true);
    };


    const order_col = [
        {
            title: 'S/N',
            dataIndex: 'sn',
            render: text => <a>{text}</a>,

        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: text => <a>{text}</a>,
        },
        {
            title: "Restaurant",
            dataIndex: 'restaurant',
        },
        {
            title: 'Ref Code',
            dataIndex: 'ref',
            render: (text) => `#${text}`,
        },
        {
            title: 'Item',
            dataIndex: 'item',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) =>(
                <Button type="link" onClick={() => handleViewClick(record.action)}>
                    View
                </Button>
            )
        },
    ]

    const shortenText = ({ text, maxLength }) => {
        return text.length <= maxLength ? text : text.slice(0, maxLength / 2 - 1) + "..." + text.slice(text.length - maxLength / 2 + 2);
    }


    let order_history_data = (order_his || []).map((order, index) => {
        return {
            key: index,
            sn: index + 1,
            date: order.created_at.split('T')[0],
            restaurant: order.restaurant.contact_name,
            ref: order.track_id,
            item: Array.isArray(order.item)
                    ? order.item.map((item) => item.quantity || 0).reduce((a, b) => a + b, 0)
                    : 0,
            status: (
                <div style={{ textTransform: "capitalize" }}>
                    {(order.status === "accepted" || order.status === "ready" || order.status === "picked" )? (
                        <Tag color="green">{order.status}</Tag>
                    ) :  order.deliveryStatus === "canceled" ? (
                        <Tag color="red">{order.status}</Tag>
                    ) : (
                        <Tag color="orange">{order.status}</Tag>
                    )}
                </div>
            ),
            action: order,
        };
    });


    return (
        <>
            <div className="userprofile">
                <div className="profile-content">
                    <Spin spinning={spinnerLoading} indicator={newAntIcon}>
                        <Navbar />
                        <div className="gray_bg_profile">
                            <div className="profile_to_left">
                                <div className="">
                                    <div className="profile_nav">
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                            <div className="profile_to_right">
                                <div className="">
                                    <div className="contain">
                                        <div className="main_container">
                                            <div className="main_container_title_div">
                                                <h5>Order History</h5>
                                                <Divider style={{ margin: '0px 0 5px' }} />
                                            </div>
                                            {/* Tables */}
                                            <div>
                                                <div className="mt-2">
                                                    {loadingData ?
                                                        <div>
                                                            <Spin indicator={newAntIcon} />
                                                        </div> :
                                                        <div>
                                                            <div className="desktop show-all">
                                                                <Table scroll={{x: true}} pagination={false} bordered
                                                                       columns={order_col}
                                                                       dataSource={order_history_data}/>
                                                                <div style={{marginTop: "2%"}}></div>
                                                            </div>

                                                            <div className="mobile topup-data">
                                                                <Collapse defaultActiveKey={[0]}>
                                                                    {order_his.map((order, index) => (
                                                                        <Collapse.Panel
                                                                            showArrow={false}
                                                                            key={index}
                                                                            header={
                                                                                <p style={{display: 'inline'}}>
                                                                                    {/*<span>*/}
                                                                                    {/*    <span className="currency">NGN</span>*/}
                                                                                    {/*    <NumberFormat*/}
                                                                                    {/*        thousandSeparator={true}*/}
                                                                                    {/*        displayType={'text'}*/}
                                                                                    {/*        value={Math.round(Number(order.total_amount) * 1)}*/}
                                                                                    {/*    />*/}
                                                                                    {/*</span>*/}
                                                                                    Ordered on {new Date(order.created_at).toLocaleString()}
                                                                                </p>
                                                                            }
                                                                        >
                                                                            <div>
                                                                                <ul className="mobile-list-flex">
                                                                                    <li>
                                                                                        <span>Date:</span>
                                                                                        <span>{order.created_at}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>Ref Code:</span>
                                                                                        <span>#{order.track_id}</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>Item:</span>
                                                                                        <span>
                                                                                            {Array.isArray(order.item)
                                                                                                ? order.item
                                                                                                    .map((item) => item.quantity || 0)
                                                                                                    .reduce((a, b) => a + b, 0)
                                                                                                : 0}
                                                                                        </span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span>Status:</span>
                                                                                        <span>
                                                                                            {(order.status === "accepted" || order.status === "ready" || order.status === "picked" )? (
                                                                                                <Tag color="green">{order.status}</Tag>
                                                                                            ) : order.deliveryStatus === "canceled" ? (
                                                                                                <Tag color="red">{order.status}</Tag>
                                                                                            ) : (
                                                                                                <Tag color="orange">{order.status}</Tag>
                                                                                            )}
                                                                                        </span>
                                                                                    </li>
                                                                                </ul>
                                                                                <div className="mt-3">
                                                                                    <Button  onClick={() => handleViewClick(order)} type="link">View
                                                                                        Order</Button>

                                                                                </div>
                                                                            </div>
                                                                        </Collapse.Panel>
                                                                    ))}
                                                                </Collapse>
                                                                <div style={{marginTop: "2%"}}></div>
                                                                <Pagination
                                                                        showSizeChanger
                                                                        current={page}
                                                                        total={total_trans}
                                                                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`}
                                                                        defaultPageSize={per_page}
                                                                        onChange={change_page}
                                                                    />
                                                            </div>

                                                            {/*<div className="mobile topup-data">*/}
                                                            {/*    <Collapse defaultActiveKey={[0]}>*/}
                                                            {/*        {*/}
                                                            {/*            order_history_data.map((order, index) => (*/}
                                                            {/*                <Collapse.Panel showArrow={false} key={index}*/}
                                                            {/*                    header={<p style={{ display: 'inline' }}><span><span className="currency">NGN</span><NumberFormat thousandSeparator={true} displayType={'text'} value={Math.round(Number(order.total_amount) * 1)} /></span> ordered on {order.created_at.split('T')[0]}</p>}>*/}
                                                            {/*                    <div>*/}
                                                            {/*                        <ul className="mobile-list-flex">*/}
                                                            {/*                            <li>*/}
                                                            {/*                                <span>Date:</span><span>{order.created_at}</span>*/}
                                                            {/*                            </li>*/}
                                                            {/*                            <li><span>Ref Code:</span><span>{order.track_id}</span></li>*/}
                                                            {/*                            <li><span>Item:</span>*/}
                                                            {/*                                <span>*/}
                                                            {/*                                   {Array.isArray(order.item)*/}
                                                            {/*                                       ? order.item.map((item) => item.quantity || 0).reduce((a, b) => a + b, 0)*/}
                                                            {/*                                       : 0*/}
                                                            {/*                                   }*/}
                                                            {/*                                </span>*/}
                                                            {/*                            </li>*/}
                                                            {/*                            <li><span>Status:</span>*/}
                                                            {/*                                <span>*/}
                                                            {/*                                     {(order.status === "accepted" || order.status === "ready" || order.status === "picked" )? (*/}
                                                            {/*                                         <Tag color="green">{order.status}</Tag>*/}
                                                            {/*                                     ) :  order.deliveryStatus === "canceled" ? (*/}
                                                            {/*                                         <Tag color="red">{order.status}</Tag>*/}
                                                            {/*                                     ) : (*/}
                                                            {/*                                         <Tag color="orange">{order.status}</Tag>*/}
                                                            {/*                                     )}*/}
                                                            {/*                                </span>*/}
                                                            {/*                            </li>*/}
                                                            {/*                        </ul>*/}
                                                            {/*                        <div className="mt-3">*/}
                                                            {/*                            <Link to={`/review-order/${order.transaction_ref}`} ><Button type="link">View Order</Button></Link>*/}
                                                            {/*                        </div>*/}
                                                            {/*                    </div>*/}
                                                            {/*                </Collapse.Panel>*/}
                                                            {/*            ))}*/}
                                                            {/*    </Collapse>*/}
                                                            {/*    <div style={{ marginTop: "2%" }}></div>*/}
                                                            {/*    /!*<Pagination showSizeChanger current={page} total={total_trans} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} orders`} defaultPageSize={per_page} onChange={change_page} />*!/*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
            <div className="profile mobile">
                <Footer/>
            </div>

            {/* Modal for Order Details */}
            <Modal
                title="Order Details"
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                centered={true}
            >
                {selectedOrder && (
                    <div>
                        <p>
                            <strong>Date:</strong>{' '}
                            {new Date(selectedOrder.created_at).toLocaleString()}
                        </p>
                        <p>
                            <strong>Ref Code:</strong> #{selectedOrder.track_id}
                        </p>
                        {
                            (selectedOrder.status === 'accepted' || selectedOrder.status === 'ready') && (
                                <p>
                                    <strong>Your Pickup Code:</strong> {selectedOrder.confirmationPin}
                                </p>
                            )
                        }

                        <p>
                            <strong>Restaurant:</strong>{' '}
                            {selectedOrder.restaurant.name}
                        </p>
                        <p>
                            <strong>Address:</strong> {selectedOrder.restaurant.address || 'N/A'}
                        </p>

                        <p>
                            <strong>Order Status: </strong>
                            {
                                selectedOrder.status === 'accepted' ? (
                                    <span>Order Accepted</span>
                                ) :
                                selectedOrder.status === 'ready' ? (
                                    <span> Order ready for pick-up</span>
                                ) :
                                selectedOrder.status === 'picked' ? (
                                    <span> Order picked up</span>
                                ) : selectedOrder.status
                            }
                        </p>

                        <p>
                            <strong>Items Bought:</strong>
                            <ul>
                                {selectedOrder.item.map((item, index) => (
                                    <li key={index}>
                                        {
                                            item.product_type === "menu" ? (
                                                <p>Menu: {item.quantity} of {item.menu.name}</p>
                                            ) :
                                            item.product_type === "extra" ? (
                                                <p> Extra: {item.quantity} of {item.extra.name}</p>
                                            ) : ""
                                        }

                                    </li>
                                ))}
                            </ul>
                        </p>
                    </div>
                )}
            </Modal>

        </>
    )
}

export default OrderHistory;
